<template>
  <v-container fluid id="industryDetail">
    <v-col>
      <v-row no-gutters class="pb-4 mt-0">
        <v-col>
          <v-btn fab text small @click="goBack" class="ma-n1">
            <v-icon color="secondary" size="30">fas fa-arrow-left</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="my-12" justify="center">
        <v-col v-if="!loadingUser" cols="12" class="pt-2">
          <v-card class="ecotrace-card" cols="12">
            <v-card-title
              >{{
                $route.params.id ? 'Editar ' : 'Cadastrar novo'
              }}
              usuário</v-card-title
            >
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col cols="3" v-if="$auth.user().id !== $route.params.id">
                    <v-autocomplete
                      v-model="user.roles"
                      :error="validation.roles.error"
                      :error-messages="validation.roles.message"
                      :items="roles.data"
                      item-text="description"
                      item-value="id"
                      label="Tipo de usuário"
                      append-icon="fas fa-chevron-down"
                      outlined
                      filled
                      flat
                      chips
                      multiple
                      solo
                      deletable-chips
                      @change="clearError('roles')"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    v-if="isEcoAdmin && $auth.user().id !== $route.params.id"
                    cols="3"
                  >
                    <v-autocomplete
                      v-model="user.group_id"
                      :items="user_groupId.data"
                      item-text="name"
                      item-value="id"
                      outlined
                      clearable
                      label="Grupo da Indústria"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="3" v-if="$auth.user().id !== $route.params.id">
                    <v-autocomplete
                      v-model="user.industries"
                      :items="industries.data"
                      item-text="name"
                      item-value="id"
                      outlined
                      filled
                      flat
                      chips
                      multiple
                      solo
                      deletable-chips
                      label="Indústria"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      v-model="user.name"
                      :error="validation.name.error"
                      :error-messages="validation.name.message"
                      label="Nome"
                      @change="clearError('name')"
                      color="secondary"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="user.email"
                      :error="validation.email.error"
                      :error-messages="validation.email.message"
                      @change="clearError('email')"
                      label="Email"
                      color="secondary"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="user.phone"
                      :error="validation.phone.error"
                      :error-messages="validation.phone.message"
                      @change="clearError('phone')"
                      label="Telefone"
                      v-mask="['(##) #####-####', '(##) ####-####']"
                      color="secondary"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="user.password"
                      :error="validation.password.error"
                      :error-messages="validation.password.message"
                      label="Senha"
                      type="password"
                      color="secondary"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <Button
                class="mr-8"
                size="medium"
                text="Voltar"
                color="quaternary"
                @do="goBack"
              />
              <Button
                size="medium"
                text="Salvar"
                :loading="loadingSaveUser"
                color="primary"
                @do="saveUser"
              />
            </v-card-actions>
          </v-card>
        </v-col>
        <v-progress-circular
          color="#315ca7"
          v-else
          indeterminate
        ></v-progress-circular>
      </v-row>
    </v-col>
    <v-snackbar
      :timeout="2000"
      top
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn dark text @click.native="snackbar.show = false" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import appApi from '@/plugins/api';
import Button from '@/components/buttons/Button';

export default {
  components: {
    Button,
  },
  data() {
    return {
      user: {
        roles: [],
        name: null,
        email: null,
        phone: null,
        password: null,
        industries: [],
        group_id: null,
      },
      snackbar: {},
      isEcoAdmin: false,
      validation: {
        roles: {
          error: false,
          message: '',
        },
        name: {
          error: false,
          message: '',
        },
        email: {
          error: false,
          message: '',
        },
        phone: {
          error: false,
          message: '',
        },
        password: {
          error: false,
          message: '',
        },
      },
      roles: [],
      industries: [],
      user_groupId: {},
      loadingUser: false,
      loadingSaveUser: false,
      showPassIcon: false,
      showGroupdField: false,
    };
  },

  async mounted() {
    await this.getInitialData();

    if (this.$route.params.id) {
      this.loadingUser = true;
      appApi
        .getUser(this.$route.params.id)
        .then((res) => {
          this.user = {
            name: res.data.name,
            email: res.data.email,
            phone: res.data.phone,
            roles: res.data.roles && res.data.roles.map((r) => r.id),
            group_id: res.data.group && res.data.group.id,
            industries: res.data.industries,
          };
        })
        .finally((r) => {
          this.loadingUser = false;
        });
    }
  },

  methods: {
    clearError(field) {
      this.validation[field].error = false;
      this.validation[field].message = '';
    },

    goBack() {
      window.history.back();
    },

    async getInitialData() {
      appApi.getIndustries().then((result) => {
        this.industries = result.data;
      });
      appApi.getRoles().then((result) => {
        this.roles = result.data;
      });

      const roles = await this.$auth.user().roles;
      this.isEcoAdmin = roles.some((r) => r.name === 'eco_admin');

      if (this.isEcoAdmin) {
        appApi.getIndustryGroups().then((result) => {
          this.user_groupId = result.data;
        });
      }
    },

    removeChips(item) {
      const index = this.roles.indexOf(item.name);
      if (index >= 0) this.roles.splice(index, 1);
    },

    saveUser() {
      this.loadingSaveUser = true;
      if (!this.$route.params.id) {
        appApi
          .postUser({
            name: this.user.name,
            email: this.user.email,
            phone: this.user.phone && this.user.phone.replace(/\D/g, ''),
            password: this.user.password,
            roles: this.user.roles,
            group_id: this.user.group_id,
            industries: this.user.industries,
          })
          .then(async () => {
            this.snackbar = {
              show: true,
              text: 'Usuário salvo com sucesso',
              color: 'success',
            };

            this.loadingSaveUser = false;

            setTimeout(() => {
              this.$router.push({ name: 'Users' });
            }, 2000);
          })
          .catch((result) => {
            this.loadingSaveUser = false;
            if (result.response && result.response.status == 400) {
              result.response.data.forEach((element) => {
                this.validation[element.field].error = true;
                this.validation[element.field].message = element.message;
              });
            }

            this.snackbar = {
              show: true,
              text: 'Erro ao salvar usuário',
              color: 'error',
            };
          });
      } else {
        appApi
          .putUser(this.$route.params.id, {
            name: this.user.name,
            email: this.user.email,
            phone: this.user.phone && this.user.phone.replace(/\D/g, ''),
            password: this.user.password !== '' && this.user.password,
            roles: this.user.roles,
            group_id: this.user.group_id,
            industries: this.user.industries,
          })
          .then(async () => {
            this.snackbar = {
              show: true,
              text: 'Usuário salvo com sucesso',
              color: 'success',
            };

            this.loadingSaveUser = false;

            setTimeout(() => {
              this.$router.push({ name: 'Users' });
            }, 2000);
          })
          .catch((result) => {
            this.loadingSaveUser = false;
            if (result.response && result.response.status == 400) {
              result.response.data.forEach((element) => {
                this.validation[element.field].error = true;
                this.validation[element.field].message = element.message;
              });
            }

            this.snackbar = {
              show: true,
              text: 'Erro ao salvar usuário',
              color: 'error',
            };
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#industryDetail::v-deep {
  .theme--light.v-input input,
  .theme--light.v-input textarea,
  .theme--light.v-select .v-select__selection--comma {
    color: $secondary-color;
    font-weight: 500;
  }

  .theme--light.v-chip:not(.v-chip--active) {
    border-radius: 5px;
    background-color: #ebf3ff;
    color: #315ca7;
  }

  .theme--light.v-chip:not(.v-chip--active) .theme--light.v-icon {
    color: #315ca7;
  }
}
</style>
