import Vue from 'vue';
import axios from 'axios';
import lscache from 'lscache';
import md5 from 'md5';

class AppApi {
  constructor() {
    this.instance = axios.create();
    lscache.flush();
  }

  call(action, method, data, fromCache) {
    fromCache = fromCache === true ? true : false;

    let url =
      process.env.VUE_APP_API_URL + (action[0] === '/' ? '' : '/') + action;

    let keyCache = null;

    let request = {
      method: method,
      url: url,
      headers: {
        Authorization: 'Bearer ' + Vue.auth.token(),
      },
    };

    if (method === 'GET') {
      request.params = data;
      keyCache = md5(url + JSON.stringify(data));
    } else {
      request.data = data;
    }

    return new Promise((resolve, reject) => {
      if (fromCache === true && keyCache && lscache.get(keyCache)) {
        resolve(lscache.get(keyCache));
      } else {
        this.instance
          .request(request)
          .then((result) => {
            if (fromCache === true && keyCache) {
              lscache.set(keyCache, result, 5);
            }
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  //  LOTES

  getLotes(data) {
    return this.call('/production-order/poultry', 'GET', data);
  }

  getLotesClean(data) {
    return this.call('/production-order/clean/poultry', 'GET', data);
  }
  getDetalheLote(id, data) {
    return this.call('/production-order/poultry/' + id, 'GET', data);
  }

  getProductionOrderBlockchainPoultry(id, data) {
    return this.call(`/production-order/blockchain/poultry/${id}`, 'GET', data);
  }

  //  Estado e cidade

  getStates(data) {
    return this.call('/states', 'GET', data);
  }

  getCities(data) {
    return this.call('/cities/', 'GET', data);
  }

  //  Indústrias

  getIndustries(data) {
    return this.call('/industries', 'GET', data);
  }

  getIndustry(id) {
    return this.call(`/industries/${id}`, 'GET');
  }

  postIndustry(data) {
    return this.call('/industries', 'POST', data);
  }

  putIndustry(id, data) {
    return this.call(`/industries/${id}`, 'PUT', data);
  }

  deleteIndustry(id) {
    return this.call(`/industries/${id}`, 'DELETE');
  }

  saveTechnicalPhoto(id, data) {
    return this.call(`/industries/${id}/tech-photo`, 'POST', data);
  }

  saveSifImage(id, data) {
    return this.call(`/industries/${id}/sif-image`, 'POST', data);
  }

  postIndustryDynamo(id) {
    return this.call(`/industries/${id}/dynamo`, 'POST');
  }

  saveIndustryImage(id, data) {
    return this.call(`/industries/${id}/images`, 'POST', data);
  }

  deleteIndustryImage(id, imageId) {
    return this.call(`/industries/${id}/images/${imageId}`, 'DELETE');
  }

  postCountries(id, data) {
    return this.call(`/industries/${id}/countries`, 'POST', data);
  }

  //  Produtores

  getExplorers(data) {
    return this.call('/productors', 'GET', data);
  }

  getExplorer(id) {
    return this.call(`/productors/${id}`, 'GET');
  }

  postExplorer(data) {
    return this.call('/productors', 'POST', data);
  }

  putExplorer(id, data) {
    return this.call(`/productors/${id}`, 'PUT', data);
  }

  deleteExplorer(id) {
    return this.call(`/productors/${id}`, 'DELETE');
  }

  saveExplorerProfileImage(id, data) {
    return this.call(`/productors/${id}/profile-image`, 'POST', data);
  }

  saveExplorerImage(id, data) {
    return this.call(`/productors/${id}/images`, 'POST', data);
  }

  deleteExplorerImage(id, imageId) {
    return this.call(`/productors/${id}/images/${imageId}`, 'DELETE');
  }

  postExplorerDynamo(id) {
    return this.call(`/productors/${id}/dynamo`, 'POST');
  }

  //  Usuários

  getRoles(data) {
    return this.call('/roles', 'GET', data);
  }

  getUsers(data) {
    return this.call('/users', 'GET', data);
  }

  getUser(id) {
    return this.call(`/users/${id}`, 'GET');
  }

  getIndustryGroups(data) {
    return this.call('/groups', 'GET', data);
  }

  postUser(data) {
    return this.call('/users', 'POST', data);
  }

  putUser(id, data) {
    return this.call(`/users/${id}`, 'PUT', data);
  }

  deleteUser(id) {
    return this.call(`/users/${id}`, 'DELETE');
  }

  // Produtos

  getProducts(data) {
    return this.call('/products', 'GET', data);
  }

  getProduct(id) {
    return this.call(`/products/${id}`, 'GET');
  }

  postProduct(data) {
    return this.call('/products', 'POST', data);
  }

  saveProductImage(id, data) {
    return this.call(`/products/${id}/image`, 'POST', data);
  }

  deleteProductImage(id) {
    return this.call(`/products/${id}/image`, 'DELETE');
  }

  putProduct(id, data) {
    return this.call(`/products/${id}`, 'PUT', data);
  }

  deleteProduct(id) {
    return this.call(`/products/${id}`, 'DELETE');
  }

  postProductDynamo(id) {
    return this.call(`/products/${id}/dynamo`, 'POST');
  }

  //reset password

  postResetPassword(data) {
    return this.call(`/auth/reset-password`, 'POST', data);
  }
  getResetPassword(token) {
    return this.call(`/auth/reset-password/${token}`, 'GET');
  }
  putResetPassword(token, data) {
    return this.call(`/auth/reset-password/${token}`, 'PUT', data);
  }
}

const appApi = new AppApi();

export default appApi;
