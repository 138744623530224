<template>
  <v-container fluid id="industryDetail">
    <v-row v-if="!loadingIndustry">
      <v-col>
        <v-row no-gutters class="pb-4 mt-0">
          <v-col>
            <v-btn fab text small @click="goBack" class="ma-n1">
              <v-icon color="secondary" size="30">fas fa-arrow-left</v-icon>
            </v-btn>
            <div class="container-form-buttons">
              <Button
                class="mr-8"
                size="medium"
                text="Voltar"
                color="quaternary"
                @do="goBack"
              />
              <Button
                size="medium"
                text="Salvar"
                :loading="loading"
                color="primary"
                @do="saveIndustry"
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="my-12">
          <v-col cols="8" class="pt-2">
            <v-card class="ecotrace-card" cols="12">
              <v-card-title>
                {{
                  this.$route.params.id ? 'Atualizar ' : 'Cadastrar nova '
                }}indústria
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="industry.group_id"
                        :error="validation.group_id.error"
                        :error-messages="validation.group_id.message"
                        :items="industryGroups.data"
                        @change="clearError('group_id')"
                        label="Grupo da Indústria"
                        item-value="id"
                        item-text="name"
                        append-icon="fas fa-chevron-down"
                        outlined
                        flat
                        solo
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="industry.name"
                        :error="validation.name.error"
                        :error-messages="validation.name.message"
                        @keyup="clearError('name')"
                        label="Nome"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="industry.official_name"
                        label="Nome Oficial"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="industry.email"
                        :error="validation.email.error"
                        :error-messages="validation.email.message"
                        @keyup="clearError('email')"
                        label="Email"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="industry.phone"
                        label="Telefone"
                        type="tel"
                        color="secondary"
                        v-mask="['(##) #####-####', '(##) ####-####']"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="industry.document_number"
                        :error="validation.document_number.error"
                        :error-messages="validation.document_number.message"
                        @keyup="clearError('document_number')"
                        v-mask="['##.###.###/####-##']"
                        label="CNPJ"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="industry.ie"
                        v-mask="['###.###.###.###']"
                        label="Inscrição Estadual"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        v-model="industry.regulating_organ"
                        :items="sifs"
                        label="Tipo documento"
                        append-icon="fas fa-chevron-down"
                        outlined
                        flat
                        solo
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="industry.organ_number"
                        :error="validation.organ_number.error"
                        :error-messages="validation.organ_number.message"
                        @keyup="clearError('organ_number')"
                        label="Número SIF"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        v-model="industry.show_analysis"
                        :items="[{text : 'Sim', value : true}, {text : 'Não', value : false}]"
                        label="Exibir Analise Ambiental?"
                        append-icon="fas fa-chevron-down"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card class="ecotrace-card" cols="12" style="min-height: 100%">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" class="pt-2">
                    <v-file-input
                      @change="(e) => getPreviewImage(e, 'industrySifImage')"
                      label="Foto do sif da indústria"
                      accept="image/png, image/jpeg, image/bmp"
                      filled
                      prepend-icon="mdi-camera"
                      outlined
                      chips
                      :error="validation.industrySifImage.error"
                      :error-messages="validation.industrySifImage.message"
                    ></v-file-input>
                  </v-col>
                  <v-col v-if="preview.industrySifImage">
                    <v-img
                      :src="preview.industrySifImage"
                      max-height="210"
                      contain
                      alt="Logo"
                      style="transform: translateY(50%)"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="my-12">
          <v-col v-if="!loadingIndustry" cols="8" class="mt-8 pt-2">
            <v-card
              class="ecotrace-card"
              cols="12"
              style="min-height: 100%; display: flex;"
            >
              <v-card-title>
                Técnico responsável
              </v-card-title>
              <v-card-text style="display: flex; align-items: center;">
                <v-form style="width: 100%">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="industry.technical_name"
                        label="Nome do técnico"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="industry.technical_document_number"
                        label="Documento do técnico"
                        v-mask="['##.###.###/####-##', '###.###.###-##']"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="4" class="mt-8">
            <v-card class="ecotrace-card" cols="12" style="min-height: 100%">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" class="pt-2">
                    <v-file-input
                      @change="
                        (e) => getPreviewImage(e, 'industryTechnicalImage')
                      "
                      label="Foto do técnico da indústria"
                      accept="image/png, image/jpeg, image/bmp"
                      filled
                      prepend-icon="mdi-camera"
                      outlined
                      chips
                      :error="validation.industryTechnicalImage.error"
                      :error-messages="
                        validation.industryTechnicalImage.message
                      "
                    ></v-file-input>
                  </v-col>
                  <v-col v-if="preview.industryTechnicalImage">
                    <v-img
                      :src="preview.industryTechnicalImage"
                      max-height="210"
                      contain
                      alt="Logo"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="my-12">
          <v-col v-if="!loadingIndustry" cols="12" class="mt-8 pt-2">
            <v-card class="ecotrace-card" cols="12">
              <v-card-title>
                Dados de endereço
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="industry.street"
                        label="Endereço"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="industry.neighborhood"
                        label="Bairro"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="industry.number"
                        label="Número"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="industry.zipcode"
                        label="CEP"
                        v-mask="['#####-###']"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="industry.complement"
                        label="Complemento"
                        color="secondary"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="industry.state_id"
                        :items="states.data"
                        item-text="initials"
                        item-value="id"
                        label="UF"
                        @change="getCities()"
                        append-icon="fas fa-chevron-down"
                        outlined
                        flat
                        solo
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="industry.city_id"
                        :items="cities"
                        :loading="loadingCities"
                        :disabled="selectCity"
                        item-text="name"
                        item-value="id"
                        label="Cidade"
                        append-icon="fas fa-chevron-down"
                        outlined
                        flat
                        solo
                        clearable
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-12 mb-6 pt-8">
          <v-col>
            <v-card class="ecotrace-card">
              <v-card-title>
                Fotos
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-row no-gutters>
                    <v-col>
                      <span class="body-2 quaternary--text"
                        >Fotos da indústria</span
                      >
                    </v-col>
                  </v-row>
                  <v-row class="mt-2">
                    <template v-for="(foto, idx) in industry.images">
                      <div :key="idx">
                        <div class="container-foto-industria">
                          <v-btn
                            text
                            icon
                            color="error"
                            @click="openDeleteFotoDialog(idx)"
                          >
                            <v-icon size="14">far fa-times-circle</v-icon>
                          </v-btn>
                          <div class="image-container d-flex justify-center">
                            <v-img
                              :src="previewIndustryImages[idx].image_url"
                              max-height="140"
                              contain
                              alt="Logo"
                            ></v-img>
                          </div>
                          <p class="quaternary--text white body-2 pt-1">
                            Enviada:
                            <span class="primary--text">
                              {{ previewIndustryImages[idx].created_at }}
                            </span>
                          </p>
                        </div>
                        <div>
                          <v-switch
                            v-model="previewIndustryImages[idx].is_default"
                            inset
                            flat
                            dense
                            @change="setDefaultImage(idx)"
                          ></v-switch>
                        </div>
                      </div>
                    </template>
                    <div class="container-btn-add">
                      <v-file-input
                        @change="addIndustryImage"
                        label="Adicionar foto da indústria"
                        accept="image/png, image/jpeg, image/bmp"
                        filled
                        prepend-icon="fas fa-plus"
                        outlined
                        chips
                        class="input-foto-granja mt-3"
                      ></v-file-input>
                    </div>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-12 mb-6 pt-8">
          <v-col cols="12">
            <v-card class="ecotrace-card">
              <v-card-title>
                Lista de países
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="5">
                    <v-text-field
                      v-model="countrySearch"
                      append-icon="mdi-magnify"
                      label="Buscar país"
                      single-line
                      dense
                      hide-details
                      class="mb-4"
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="3" class="d-flex justify-end align-center">
                    <span class="body-2 quaternary--text"
                      >Países habilitados:
                      <b
                        class="subtitle-1 font-weight-medium secondary--text"
                        >{{ enabledCount }}</b
                      >
                    </span>
                  </v-col>
                </v-row>
                <v-data-table
                  :loading="loadingCountries"
                  loading-text="Carregando..."
                  :headers="countriesListHeaders"
                  :items="countries"
                  expand-icon="fas fa-chevron-down"
                  hide-default-footer
                  :items-per-page="300"
                  :height="500"
                  :search="countrySearch"
                >
                  <template v-slot:item.enabled_date="{ item }">
                    <div
                      class="data-input-container d-flex align-center justify-center"
                      :class="item.enabledError && 'data-error mt-4'"
                    >
                      <v-icon size="18" class="mr-3"
                        >far fa-calendar-alt</v-icon
                      >
                      <TheMask
                        @keyup.native="
                          validateDateField(
                            item,
                            'enabled_date',
                            'enabledError'
                          )
                        "
                        v-model="item.enabled_date"
                        masked
                        :mask="['##/##/####']"
                        placeholder="DD/MM/AAAA"
                      />
                    </div>
                    <p class="error--text mb-1" v-if="item.enabledError">
                      Insira uma data válida
                    </p>
                  </template>

                  <template
                    v-slot:item.last_audit_date="{ item }"
                    class="Teste"
                  >
                    <div
                      class="data-input-container d-flex align-center justify-center"
                      :class="item.auditError && 'data-error mt-4'"
                    >
                      <v-icon size="18" class="mr-3"
                        >far fa-calendar-alt</v-icon
                      >

                      <TheMask
                        @keyup.native="
                          validateDateField(
                            item,
                            'last_audit_date',
                            'auditError'
                          )
                        "
                        v-model="item.last_audit_date"
                        :mask="['##/##/####']"
                        masked
                        placeholder="DD/MM/AAAA"
                      />
                    </div>
                    <p class="error--text mb-1" v-if="item.auditError">
                      Insira uma data válida
                    </p>
                  </template>

                  <template v-slot:item.is_enabled="{ item }">
                    <v-checkbox
                      v-model="item.is_enabled"
                      color="secondary"
                      class="country-checkbox"
                      @change="countEnabledCountry"
                    ></v-checkbox>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center" v-else>
      <v-progress-circular color="#315ca7" indeterminate></v-progress-circular>
    </v-row>

    <v-dialog v-model="deleteFotoDialog" persistent max-width="290">
      <v-card class="pa-4">
        <v-card-title class="headline" color="error">
          Deletar foto
        </v-card-title>
        <v-card-text>
          <span class="subtitle-1">
            Você tem certeza que deseja deletar a foto da industria?
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="quaternary"
            dark
            @click="deleteFotoDialog = !deleteFotoDialog"
            >Não</v-btn
          >
          <v-btn
            color="primary"
            :loading="loadingDeleteModal"
            @click="removeIndustryImage(selectedFotoToDelete)"
            >Sim</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="2000"
      top
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn dark text @click.native="snackbar.show = false" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import appApi from '@/plugins/api';
import Button from '@/components/buttons/Button';

import { TheMask, mask } from 'vue-the-mask';
import axios from 'axios';
const moment = require('moment');

const instance = axios.create();

export default {
  components: {
    Button,
    TheMask,
  },

  directives: { mask },

  data() {
    return {
      selectCity: true,
      industry: {
        name: null,
        official_name: null,
        document_number: null,
        ie: null,
        technical_document_number: null,
        technical_name: null,
        regulating_organ: null,
        organ_number: null,
        group_id: null,
        email: null,
        phone: null,
        city_id: null,
        state_id: null,
        street: null,
        number: null,
        neighborhood: null,
        zipcode: null,
        complement: null,
        id: null,
        images: [],
        show_analysis : false
      },
      loadingIndustry: false,
      loadingCities: false,
      validation: {
        name: {
          error: false,
          message: '',
        },

        document_number: {
          error: false,
          message: '',
        },

        group_id: {
          error: false,
          message: '',
        },
        email: {
          error: false,
          message: '',
        },
        industryTechnicalImage: {
          error: false,
          message: '',
        },
        industrySifImage: {
          error: false,
          message: '',
        },
        organ_number: {
          error: false,
          message: '',
        },
      },
      countriesListHeaders: [
        { text: 'País', align: 'center', value: 'country_name' },
        {
          text: 'Data de habilitação',
          align: 'center',
          filterable: false,
          value: 'enabled_date',
        },
        {
          text: 'Última data de auditoria',
          align: 'center',
          filterable: false,
          value: 'last_audit_date',
        },
        {
          text: 'Habilitado',
          align: 'center',
          value: 'is_enabled',
          filterable: false,
          sortable: false,
        },
      ],
      countries: [],
      countrySearch: '',
      enabledCount: 0,
      modal: {
        enabled_date: false,
        last_audit_date: false,
      },
      initialDataError: false,
      lastDataError: false,
      loadingCountries: false,
      sifs: ['SIF'],
      industryGroups: [],
      previewIndustryImages: [],
      states: {},
      cities: [],
      loading: false,
      preview: { industryTechnicalImage: null, industrySifImage: null },
      industryTechnicalImage: null,
      industrySifImage: null,
      loadingDeleteModal: false,
      deleteFotoDialog: false,
      selectedFotoToDelete: null,
      snackbar: {},
      defaultCountries: [
        {
          country_id: 1,
          country_name: 'Afeganistão',
          country_initials: 'AF',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 2,
          country_name: 'África do Sul',
          country_initials: 'ZA',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 3,
          country_name: 'Albânia',
          country_initials: 'AL',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 4,
          country_name: 'Alemanha',
          country_initials: 'DE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 5,
          country_name: 'Andorra',
          country_initials: 'AD',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 6,
          country_name: 'Angola',
          country_initials: 'AO',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 7,
          country_name: 'Anguilla',
          country_initials: 'AI',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 8,
          country_name: 'Antárta',
          country_initials: 'AQ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 9,
          country_name: 'Antígua e Barbuda',
          country_initials: 'AG',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 10,
          country_name: 'Antilhas Holandesas',
          country_initials: 'AN',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 11,
          country_name: 'Arábia Saudita',
          country_initials: 'SA',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 12,
          country_name: 'Argélia',
          country_initials: 'DZ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 13,
          country_name: 'Argentina',
          country_initials: 'AR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 14,
          country_name: 'Armênia',
          country_initials: 'AM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 15,
          country_name: 'Aruba',
          country_initials: 'AW',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 16,
          country_name: 'Austrália',
          country_initials: 'AU',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 17,
          country_name: 'Áustria',
          country_initials: 'AT',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 18,
          country_name: 'Azerbaijão',
          country_initials: 'AZ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 19,
          country_name: 'Bahamas',
          country_initials: 'BS',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 20,
          country_name: 'Bahrein',
          country_initials: 'BH',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 21,
          country_name: 'Bangladesh',
          country_initials: 'BD',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 22,
          country_name: 'Barbados',
          country_initials: 'BB',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 23,
          country_name: 'Belarus',
          country_initials: 'BY',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 24,
          country_name: 'Bélgica',
          country_initials: 'BE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 25,
          country_name: 'Belize',
          country_initials: 'BZ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 26,
          country_name: 'Benin',
          country_initials: 'BJ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 27,
          country_name: 'Bermudas',
          country_initials: 'BM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 28,
          country_name: 'Bolívia',
          country_initials: 'BO',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 29,
          country_name: 'Bósnia-Herzegóvina',
          country_initials: 'BA',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 30,
          country_name: 'Botsuana',
          country_initials: 'BW',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 31,
          country_name: 'Brasil',
          country_initials: 'BR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 32,
          country_name: 'Brunei',
          country_initials: 'BN',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 33,
          country_name: 'Bulgária',
          country_initials: 'BG',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 34,
          country_name: 'Burkina Fasso',
          country_initials: 'BF',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 35,
          country_name: 'Burundi',
          country_initials: 'BI',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 36,
          country_name: 'Butão',
          country_initials: 'BT',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 37,
          country_name: 'Cabo Verde',
          country_initials: 'CV',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 38,
          country_name: 'Camarões',
          country_initials: 'CM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 39,
          country_name: 'Camboja',
          country_initials: 'KH',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 40,
          country_name: 'Canadá',
          country_initials: 'CA',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 41,
          country_name: 'Cazaquistão',
          country_initials: 'KZ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 42,
          country_name: 'Chade',
          country_initials: 'TD',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 43,
          country_name: 'Chile',
          country_initials: 'CL',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 44,
          country_name: 'China',
          country_initials: 'CN',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 45,
          country_name: 'Chipre',
          country_initials: 'CY',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 46,
          country_name: 'Cingapura',
          country_initials: 'SG',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 47,
          country_name: 'Colômbia',
          country_initials: 'CO',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 48,
          country_name: 'Congo',
          country_initials: 'CG',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 49,
          country_name: 'Coréia do Norte',
          country_initials: 'KP',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 50,
          country_name: 'Coréia do Sul',
          country_initials: 'KR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 51,
          country_name: 'Costa do Marfim',
          country_initials: 'CI',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 52,
          country_name: 'Costa Rica',
          country_initials: 'CR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 53,
          country_name: 'Croácia (Hrvatska)',
          country_initials: 'HR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 54,
          country_name: 'Cuba',
          country_initials: 'CU',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 55,
          country_name: 'Dinamarca',
          country_initials: 'DK',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 56,
          country_name: 'Djibuti',
          country_initials: 'DJ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 57,
          country_name: 'Dominica',
          country_initials: 'DM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 58,
          country_name: 'Egito',
          country_initials: 'EG',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 59,
          country_name: 'El Salvador',
          country_initials: 'SV',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 60,
          country_name: 'Emirados Árabes Unidos',
          country_initials: 'AE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 61,
          country_name: 'Equador',
          country_initials: 'EC',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 62,
          country_name: 'Eritréia',
          country_initials: 'ER',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 63,
          country_name: 'Eslováquia',
          country_initials: 'SK',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 64,
          country_name: 'Eslovênia',
          country_initials: 'SI',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 65,
          country_name: 'Espanha',
          country_initials: 'ES',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 66,
          country_name: 'Estados Unidos',
          country_initials: 'US',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 67,
          country_name: 'Estônia',
          country_initials: 'EE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 68,
          country_name: 'Etiópia',
          country_initials: 'ET',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 69,
          country_name: 'Fiji',
          country_initials: 'FJ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 70,
          country_name: 'Filipinas',
          country_initials: 'PH',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 71,
          country_name: 'Finlândia',
          country_initials: 'FI',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 72,
          country_name: 'França',
          country_initials: 'FR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 73,
          country_name: 'Gabão',
          country_initials: 'GA',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 74,
          country_name: 'Gâmbia',
          country_initials: 'GM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 75,
          country_name: 'Gana',
          country_initials: 'GH',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 76,
          country_name: 'Geórgia',
          country_initials: 'GE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 77,
          country_name: 'Gibraltar',
          country_initials: 'GI',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 78,
          country_name: 'Grã-Bretanha (Reino Unido, UK)',
          country_initials: 'GB',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 79,
          country_name: 'Granada',
          country_initials: 'GD',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 80,
          country_name: 'Grécia',
          country_initials: 'GR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 81,
          country_name: 'Groelândia',
          country_initials: 'GL',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 82,
          country_name: 'Guadalupe',
          country_initials: 'GP',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 83,
          country_name: 'Guam (Território dos Estados Unidos)',
          country_initials: 'GU',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 84,
          country_name: 'Guatemala',
          country_initials: 'GT',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 85,
          country_name: 'Guernsey',
          country_initials: 'G',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 86,
          country_name: 'Guiana',
          country_initials: 'GY',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 87,
          country_name: 'Guiana Francesa',
          country_initials: 'GF',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 88,
          country_name: 'Guiné',
          country_initials: 'GN',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 89,
          country_name: 'Guiné Equatorial',
          country_initials: 'GQ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 90,
          country_name: 'Guiné-Bissau',
          country_initials: 'GW',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 91,
          country_name: 'Haiti',
          country_initials: 'HT',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 92,
          country_name: 'Holanda',
          country_initials: 'NL',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 93,
          country_name: 'Honduras',
          country_initials: 'HN',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 94,
          country_name: 'Hong Kong',
          country_initials: 'HK',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 95,
          country_name: 'Hungria',
          country_initials: 'HU',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 96,
          country_name: 'Iêmen',
          country_initials: 'YE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 97,
          country_name: 'Ilha Bouvet (Território da Noruega)',
          country_initials: 'BV',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 98,
          country_name: 'Ilha do Homem',
          country_initials: 'IM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 99,
          country_name: 'Ilha Natal',
          country_initials: 'CX',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 100,
          country_name: 'Ilha Pitcairn',
          country_initials: 'PN',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 101,
          country_name: 'Ilha Reunião',
          country_initials: 'RE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 102,
          country_name: 'Ilhas Aland',
          country_initials: 'AX',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 103,
          country_name: 'Ilhas Cayman',
          country_initials: 'KY',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 104,
          country_name: 'Ilhas Cocos',
          country_initials: 'CC',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 105,
          country_name: 'Ilhas Comores',
          country_initials: 'KM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 106,
          country_name: 'Ilhas Cook',
          country_initials: 'CK',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 107,
          country_name: 'Ilhas Faroes',
          country_initials: 'FO',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 108,
          country_name: 'Ilhas Falkland (Malvinas)',
          country_initials: 'FK',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 109,
          country_name: 'Ilhas Geórgia do Sul e Sandwich do Sul',
          country_initials: 'GS',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 110,
          country_name: 'Ilhas Heard e McDonald (Território da Austrália)',
          country_initials: 'HM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 111,
          country_name: 'Ilhas Marianas do Norte',
          country_initials: 'MP',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 112,
          country_name: 'Ilhas Marshall',
          country_initials: 'MH',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 113,
          country_name: 'Ilhas Menores dos Estados Unidos',
          country_initials: 'UM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 114,
          country_name: 'Ilhas Norfolk',
          country_initials: 'NF',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 115,
          country_name: 'Ilhas Seychelles',
          country_initials: 'SC',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 116,
          country_name: 'Ilhas Solomão',
          country_initials: 'SB',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 117,
          country_name: 'Ilhas Svalbard e Jan Mayen',
          country_initials: 'SJ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 118,
          country_name: 'Ilhas Tokelau',
          country_initials: 'TK',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 119,
          country_name: 'Ilhas Turks e Caicos',
          country_initials: 'TC',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 120,
          country_name: 'Ilhas Virgens (Estados Unidos)',
          country_initials: 'VI',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 121,
          country_name: 'Ilhas Virgens (Inglaterra)',
          country_initials: 'VG',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 122,
          country_name: 'Ilhas Wallis e Futuna',
          country_initials: 'WF',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 123,
          country_name: 'índia',
          country_initials: 'IN',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 124,
          country_name: 'Indonésia',
          country_initials: 'ID',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 125,
          country_name: 'Irã',
          country_initials: 'IR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 126,
          country_name: 'Iraque',
          country_initials: 'IQ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 127,
          country_name: 'Irlanda',
          country_initials: 'IE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 128,
          country_name: 'Islândia',
          country_initials: 'IS',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 129,
          country_name: 'Israel',
          country_initials: 'IL',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 130,
          country_name: 'Itália',
          country_initials: 'IT',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 131,
          country_name: 'Jamaica',
          country_initials: 'JM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 132,
          country_name: 'Japão',
          country_initials: 'JP',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 133,
          country_name: 'Jersey',
          country_initials: 'JE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 134,
          country_name: 'Jordânia',
          country_initials: 'JO',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 135,
          country_name: 'Kênia',
          country_initials: 'KE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 136,
          country_name: 'Kiribati',
          country_initials: 'KI',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 137,
          country_name: 'Kuait',
          country_initials: 'KW',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 138,
          country_name: 'Laos',
          country_initials: 'LA',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 139,
          country_name: 'Látvia',
          country_initials: 'LV',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 140,
          country_name: 'Lesoto',
          country_initials: 'LS',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 141,
          country_name: 'Líbano',
          country_initials: 'LB',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 142,
          country_name: 'Libéria',
          country_initials: 'LR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 143,
          country_name: 'Líbia',
          country_initials: 'LY',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 144,
          country_name: 'Liechtenstein',
          country_initials: 'LI',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 145,
          country_name: 'Lituânia',
          country_initials: 'LT',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 146,
          country_name: 'Luxemburgo',
          country_initials: 'LU',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 147,
          country_name: 'Macau',
          country_initials: 'MO',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 148,
          country_name: 'Macedônia (República Yugoslava)',
          country_initials: 'MK',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 149,
          country_name: 'Madagascar',
          country_initials: 'MG',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 150,
          country_name: 'Malásia',
          country_initials: 'MY',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 151,
          country_name: 'Malaui',
          country_initials: 'MW',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 152,
          country_name: 'Maldivas',
          country_initials: 'MV',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 153,
          country_name: 'Mali',
          country_initials: 'ML',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 154,
          country_name: 'Malta',
          country_initials: 'MT',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 155,
          country_name: 'Marrocos',
          country_initials: 'MA',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 156,
          country_name: 'Martinica',
          country_initials: 'MQ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 157,
          country_name: 'Maurício',
          country_initials: 'MU',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 158,
          country_name: 'Mauritânia',
          country_initials: 'MR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 159,
          country_name: 'Mayotte',
          country_initials: 'YT',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 160,
          country_name: 'México',
          country_initials: 'MX',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 161,
          country_name: 'Micronésia',
          country_initials: 'FM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 162,
          country_name: 'Moçambique',
          country_initials: 'MZ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 163,
          country_name: 'Moldova',
          country_initials: 'MD',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 164,
          country_name: 'Mônaco',
          country_initials: 'MC',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 165,
          country_name: 'Mongólia',
          country_initials: 'MN',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 166,
          country_name: 'Montenegro',
          country_initials: 'ME',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 167,
          country_name: 'Montserrat',
          country_initials: 'MS',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 168,
          country_name: 'Myanma',
          country_initials: 'MM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 169,
          country_name: 'Namíbia',
          country_initials: 'NA',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 170,
          country_name: 'Nauru',
          country_initials: 'NR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 171,
          country_name: 'Nepal',
          country_initials: 'NP',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 172,
          country_name: 'Nicarágua',
          country_initials: 'NI',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 173,
          country_name: 'Níger',
          country_initials: 'NE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 174,
          country_name: 'Nigéria',
          country_initials: 'NG',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 175,
          country_name: 'Niue',
          country_initials: 'NU',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 176,
          country_name: 'Noruega',
          country_initials: 'NO',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 177,
          country_name: 'Nova Caledônia',
          country_initials: 'NC',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 178,
          country_name: 'Nova Zelândia',
          country_initials: 'NZ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 179,
          country_name: 'Omã',
          country_initials: 'OM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 180,
          country_name: 'Palau',
          country_initials: 'PW',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 181,
          country_name: 'Panamá',
          country_initials: 'PA',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 182,
          country_name: 'Papua-Nova Guiné',
          country_initials: 'PG',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 183,
          country_name: 'Paquistão',
          country_initials: 'PK',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 184,
          country_name: 'Paraguai',
          country_initials: 'PY',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 185,
          country_name: 'Peru',
          country_initials: 'PE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 186,
          country_name: 'Polinésia Francesa',
          country_initials: 'PF',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 187,
          country_name: 'Polônia',
          country_initials: 'PL',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 188,
          country_name: 'Porto Rico',
          country_initials: 'PR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 189,
          country_name: 'Portugal',
          country_initials: 'PT',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 190,
          country_name: 'Qatar',
          country_initials: 'QA',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 191,
          country_name: 'Quirguistão',
          country_initials: 'KG',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 192,
          country_name: 'República Centro-Africana',
          country_initials: 'CF',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 193,
          country_name: 'República Democrática do Congo',
          country_initials: 'CD',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 194,
          country_name: 'República Dominicana',
          country_initials: 'DO',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 195,
          country_name: 'República Tcheca',
          country_initials: 'CZ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 196,
          country_name: 'Romênia',
          country_initials: 'RO',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 197,
          country_name: 'Ruanda',
          country_initials: 'RW',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 198,
          country_name: 'Rússia (antiga URSS) - Federação Russa',
          country_initials: 'RU',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 199,
          country_name: 'Saara Ocidental',
          country_initials: 'EH',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 200,
          country_name: 'Saint Vincente e Granadinas',
          country_initials: 'VC',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 201,
          country_name: 'Samoa Americana',
          country_initials: 'AS',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 202,
          country_name: 'Samoa Ocidental',
          country_initials: 'WS',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 203,
          country_name: 'San Marino',
          country_initials: 'SM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 204,
          country_name: 'Santa Helena',
          country_initials: 'SH',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 205,
          country_name: 'Santa Lúcia',
          country_initials: 'LC',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 206,
          country_name: 'São Bartolomeu',
          country_initials: 'BL',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 207,
          country_name: 'São Cristóvão e Névis',
          country_initials: 'KN',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 208,
          country_name: 'São Martim',
          country_initials: 'MF',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 209,
          country_name: 'São Tomé e Príncipe',
          country_initials: 'ST',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 210,
          country_name: 'Senegal',
          country_initials: 'SN',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 211,
          country_name: 'Serra Leoa',
          country_initials: 'SL',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 212,
          country_name: 'Sérvia',
          country_initials: 'RS',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 213,
          country_name: 'Síria',
          country_initials: 'SY',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 214,
          country_name: 'Somália',
          country_initials: 'SO',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 215,
          country_name: 'Sri Lanka',
          country_initials: 'LK',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 216,
          country_name: 'St. Pierre and Miquelon',
          country_initials: 'PM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 217,
          country_name: 'Suazilândia',
          country_initials: 'SZ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 218,
          country_name: 'Sudão',
          country_initials: 'SD',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 219,
          country_name: 'Suécia',
          country_initials: 'SE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 220,
          country_name: 'Suíça',
          country_initials: 'CH',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 221,
          country_name: 'Suriname',
          country_initials: 'SR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 222,
          country_name: 'Tadjiquistão',
          country_initials: 'TJ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 223,
          country_name: 'Tailândia',
          country_initials: 'TH',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 224,
          country_name: 'Taiwan',
          country_initials: 'TW',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 225,
          country_name: 'Tanzânia',
          country_initials: 'TZ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 226,
          country_name: 'Território Britânico do Oceano índico',
          country_initials: 'IO',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 227,
          country_name: 'Territórios do Sul da França',
          country_initials: 'TF',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 228,
          country_name: 'Territórios Palestinos Ocupados',
          country_initials: 'PS',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 229,
          country_name: 'Timor Leste',
          country_initials: 'TP',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 230,
          country_name: 'Togo',
          country_initials: 'TG',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 231,
          country_name: 'Tonga',
          country_initials: 'TO',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 232,
          country_name: 'Trinad and Tobago',
          country_initials: 'TT',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 233,
          country_name: 'Tunísia',
          country_initials: 'TN',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 234,
          country_name: 'Turcomenistão',
          country_initials: 'TM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 235,
          country_name: 'Turquia',
          country_initials: 'TR',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 236,
          country_name: 'Tuvalu',
          country_initials: 'TV',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 237,
          country_name: 'Ucrânia',
          country_initials: 'UA',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 238,
          country_name: 'Uganda',
          country_initials: 'UG',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 239,
          country_name: 'Uruguai',
          country_initials: 'UY',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 240,
          country_name: 'Uzbequistão',
          country_initials: 'UZ',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 241,
          country_name: 'Vanuatu',
          country_initials: 'VU',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 242,
          country_name: 'Vaticano',
          country_initials: 'VA',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 243,
          country_name: 'Venezuela',
          country_initials: 'VE',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 244,
          country_name: 'Vietnã',
          country_initials: 'VN',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 245,
          country_name: 'Zâmbia',
          country_initials: 'ZM',
          enabledError: false,
          auditError: false,
        },
        {
          country_id: 246,
          country_name: 'Zimbábue',
          country_initials: 'ZW',
          enabledError: false,
          auditError: false,
        },
      ],
    };
  },

  mounted() {
    this.getStates();

    if (this.$route.params.id) {
      this.loadingIndustry = true;

      appApi
        .getIndustry(this.$route.params.id)
        .then(({ data: industry }) => {
          const { address = {} } = industry;

          this.industry = {
            name: industry.name,
            official_name: industry.official_name,
            document_number: industry.document_number,
            ie: industry.ie,
            regulating_organ: industry.regulating_organ,
            organ_number: industry.organ_number,
            technical_name: industry.technical_name,
            technical_document_number: industry.technical_document_number,
            group_id: industry.group && industry.group.id,
            email: industry.email,
            phone: industry.phone,

            address_id: address && address.id,
            city_id: address && address.city_id,
            state_id: address && address.state_id,

            street: address && address.street,
            number: address && address.number,
            neighborhood: address && address.neighborhood,
            zipcode: address && address.zipcode,
            complement: address && address.complement,

            images: (industry.images && industry.images.map((i) => i)) || [],

            show_analysis : industry.show_analysis
          };

          this.handleCountries(industry);

          this.handleIndustryImages(industry);

          this.getCities();
        })
        .finally((r) => {
          this.loadingIndustry = false;
        });
    } else {
      this.countries = this.defaultCountries;
    }
  },

  methods: {
    compare(a, b) {
      const upperA = a.country_name.toUpperCase();
      const upperb = b.country_name.toUpperCase();

      let comparison = 0;
      if (upperA > upperb) {
        comparison = 1;
      } else if (upperA < upperb) {
        comparison = -1;
      }
      return comparison;
    },
    setDefaultImage(idx) {
      this.previewIndustryImages.forEach((element, i) => {
        i !== idx ? (element.is_default = false) : (element.is_default = true);
      });
    },
    goBack() {
      window.history.back();
    },

    handleIndustryImages(industry) {
      this.previewIndustryImages =
        industry.images &&
        industry.images.map((i) => {
          return {
            image_url: i.image_url,
            id: i.id,
            created_at: moment(i.created_at).format('DD/MM/YYYY'),
            is_default: i.is_default,
          };
        });

      this.preview.industrySifImage = industry.sif_image_image_url;
      this.preview.industryTechnicalImage = industry.technical_photo_url;
    },

    handleCountries(industry) {
      last_audit_date: null;

      let industryCountries =
        (industry.countries.length > 0 &&
          industry.countries.map((i) => {
            return {
              ...i,
              enabled_date:
                i.enabled_date && moment(i.enabled_date).format('DDMMYYYY'),
              iso_enabled_date: i.enabled_date,
              iso_last_audit_date: i.last_audit_date,
              last_audit_date:
                i.last_audit_date &&
                moment(i.last_audit_date).format('DDMMYYYY'),
              enabledError: false,
              auditError: false,
            };
          })) ||
        [];

      let difference = this.defaultCountries.filter(
        (x) => !industryCountries.find((y) => x.country_id === y.country_id)
      );

      this.countries = [...industryCountries, ...difference];

      this.countEnabledCountry();
    },

    getStates() {
      appApi
        .getStates()
        .then((result) => {
          this.states = result.data;
        })
        .catch((error) => {
          console.log('Erro ao tentar listar estados');
        });

      appApi.getIndustryGroups().then((result) => {
        this.industryGroups = result.data;
      });
    },

    getCities() {
      if (this.industry.state_id) {
        this.loadingCities = true;
        appApi
          .getCities({
            stateId: this.industry.state_id,
          })
          .then((result) => {
            this.cities = result.data.data;
            this.selectCity = false;
            this.loadingCities = false;
          });
      } else {
        this.industry.city_id = null;
        this.cities = [];
      }
    },

    getPreviewImage(fileInput, field) {
      if (fileInput) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview[field] = e.target.result;
        };
        reader.readAsDataURL(fileInput);

        this[field] = fileInput;
      } else {
        this.preview[field] = null;
        this[field] = null;
      }
    },

    async saveInitialImageData(industryId, apiMethod, image, field) {
      if (image) {
        try {
          let formData = new FormData();

          const { data } = await appApi[apiMethod](industryId, {
            filename: image.name,
          });

          for (let key in data.fields) {
            formData.append(key, data.fields[key]);
          }

          formData.append('file', image);

          await instance
            .post(data.url, formData, {
              headers: formData,
            })
            .then((res) => {});
        } catch (err) {
          console.log(err);

          this.validation[field] = {
            error: true,
            message: 'Erro ao enviar imagem, contate seu administrador',
          };

          throw new Error(err);
        }
      }

      return industryId;
    },

    validateDateField(item, field, error) {
      item[error] = false;

      if (item[field].length !== 0 && item[field] !== 10) {
        item[error] = true;
      }

      if (item[field].length === 0) {
        item[`iso_${field}`] = '';
      }

      if (item[field].length === 10) {
        const [day, month, year] = item[field].split('/');
        const isoDate = `${year}-${month}-${day}`;
        if (moment(isoDate).isValid()) {
          item[error] = false;
          item[`iso_${field}`] = isoDate;
        } else {
          item[error] = true;
        }
      }
    },

    clearError(field) {
      this.validation[field].error = false;
      this.validation[field].message = '';
    },

    addIndustryImage(fileInput) {
      if (fileInput) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewIndustryImages.push({
            image_url: e.target.result,
            created_at: moment().format('DD/MM/YYYY'),
          });

          this.industry.images.push(fileInput);
        };
        reader.readAsDataURL(fileInput);
      }
    },

    async saveIndustryImages(industryId) {
      if (this.industry.images.length > 0) {
        for (const [idx, image] of this.previewIndustryImages.entries()) {
          await this.saveSingleIndustryImage(
            industryId,
            image,
            this.industry.images[idx]
          );
        }
      }

      return industryId;
    },

    async saveSingleIndustryImage(industryId, previewImage, fileImage) {
      let formData = new FormData();

      const { data } = await appApi.saveIndustryImage(industryId, {
        filename: fileImage.name,
        is_default: previewImage.is_default,
        id: previewImage.id,
      });

      if (!data) return;

      for (let key in data.fields) {
        formData.append(key, data.fields[key]);
      }

      formData.append('file', fileImage);

      try {
        await instance
          .post(data.url, formData, {
            headers: formData,
          })
          .then((res) => {});
      } catch (error) {
        console.log(error);
      }
    },

    openDeleteFotoDialog(idx) {
      this.selectedFotoToDelete = idx;
      this.deleteFotoDialog = true;
    },

    removeIndustryImage(idx) {
      if (this.previewIndustryImages[idx].id) {
        this.loadingDeleteModal = true;
        appApi.deleteIndustryImage(
          this.$route.params.id,
          this.previewIndustryImages[idx].id
        );
        this.loadingDeleteModal = false;
      }
      this.previewIndustryImages.splice(idx, 1);
      this.industry.images.splice(idx, 1);

      this.deleteFotoDialog = false;
      this.snackbar = {
        show: true,
        text: 'Imagem deletada com sucesso',
        color: 'primary',
      };
    },

    async saveCountriesData(id) {
      return new Promise(async (resolve, reject) => {
        let countriesData = this.countries.filter(
          (i) =>
            i.is_enabled || i.id || i.iso_enabled_date || i.iso_last_audit_date
        );

        const res = await appApi.postCountries(id, {
          countries: countriesData,
        });

        resolve(id);
      });
    },

    countEnabledCountry(newValue) {
      this.enabledCount = this.countries.reduce((sum, item) => {
        if (item.is_enabled) {
          sum++;
        }
        return sum;
      }, 0);
    },

    sanatizeStrings() {
      return {
        name: this.industry.name,
        official_name: this.industry.official_name,
        document_number:
          this.industry.document_number &&
          this.industry.document_number.replace(/\D/g, ''),
        ie: this.industry.ie && this.industry.ie.replace(/\D/g, ''),
        technical_document_number:
          this.industry.technical_document_number &&
          this.industry.technical_document_number.replace(/\D/g, ''),
        technical_name: this.industry.technical_name,
        regulating_organ: this.industry.regulating_organ,
        organ_number: this.industry.organ_number,
        group_id: this.industry.group_id,
        email: this.industry.email,
        phone: this.industry.phone && this.industry.phone.replace(/\D/g, ''),
        id: this.industry.id,
        state_id: this.industry.state_id,
        city_id: this.industry.city_id,
        street: this.industry.street,
        number: this.industry.number,
        neighborhood: this.industry.neighborhood,
        zipcode:
          this.industry.zipcode && this.industry.zipcode.replace(/\D/g, ''),
        complement: this.industry.complement,
        show_analysis : this.industry.show_analysis || false
      };
    },

    async saveIndustry() {
      const industryDataSinatized = this.sanatizeStrings();
      this.loading = true;
      if (!this.$route.params.id) {
        appApi
          .postIndustry(industryDataSinatized)
          .then((res) =>
            this.saveInitialImageData(
              res.data.id,
              'saveTechnicalPhoto',
              this.industryTechnicalImage,
              'industryTechnicalImage'
            )
          )
          .then(this.saveIndustryImages)
          .then(this.saveCountriesData)
          .then((industryId) =>
            this.saveInitialImageData(
              industryId,
              'saveSifImage',
              this.industrySifImage,
              'industrySifImage'
            )
          )
          // .then((id) => {
          //   appApi.postIndustryDynamo(id);
          // })
          .then(this.handleSuccess)
          .catch(this.handleError)
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.saveInitialImageData(
          this.$route.params.id,
          'saveTechnicalPhoto',
          this.industryTechnicalImage,
          'industryTechnicalImage'
        )
          .then((industryId) =>
            this.saveInitialImageData(
              industryId,
              'saveSifImage',
              this.industrySifImage,
              'industrySifImage'
            )
          )
          .then(this.saveIndustryImages)
          .then(this.saveCountriesData)
          .then(() =>
            appApi.putIndustry(this.$route.params.id, industryDataSinatized)
          )
          // .then(() => appApi.postIndustryDynamo(this.$route.params.id))
          .then(this.handleSuccess)
          .catch(this.handleError)
          .finally(() => {
            this.loading = false;
          });
      }
    },

    handleSuccess() {
      this.snackbar = {
        show: true,
        text: 'Indústria salva com sucesso',
        color: 'success',
      };
      setTimeout(() => {
        this.$router.push({ name: 'Industries' });
      }, 2000);
    },

    handleError(err) {
      if (err.response && err.response.status == 400) {
        err.response.data.forEach((element) => {
          this.validation[element.field].error = true;
          this.validation[element.field].message = element.message;
        });
      }

      this.snackbar = {
        show: true,
        text: 'Erro ao salvar indústria',
        color: 'error',
      };

      console.log(err);
    },
  },
};
</script>

<style lang="scss" scoped>
#industryDetail::v-deep {
  .container-form-buttons {
    position: absolute;
    display: flex;
    right: 30px;
  }

  .ecotrace-card .v-card__text .v-input__slot {
    box-shadow: none !important;
  }

  .container-foto-industria {
    position: relative;
    width: 140px;
    margin: 0 15px;
    background-color: #f2f3f7;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 30px;
    }

    .image-container {
      width: 100%;
      height: 140px;
    }

    button {
      position: absolute;
      top: -5px;
      right: -5px;
      height: 27px;
      width: 27px;
      background-color: white;
      z-index: 10;
    }

    p {
      margin: 0;
      letter-spacing: 1.1px !important;
    }
  }

  .container-btn-add {
    width: 140px;
    height: 164px;
    position: relative;

    .input-foto-granja {
      height: 100%;
      margin: 0 !important;

      .v-input__prepend-outer {
        width: 100%;
        height: 100%;
        margin: 0;

        .v-input__icon {
          height: 100%;

          .v-icon {
            width: 100%;
            height: 100%;
            background-color: #f2f3f7;

            &::before {
              font-size: 33px;
            }
          }
        }
      }

      .v-input__control {
        visibility: hidden;
        width: 0;
        height: 0;
        opacity: 0;
      }
    }
  }

  .country-checkbox {
    .v-input__slot {
      display: flex;
      justify-content: center;
      box-shadow: none;
    }
  }

  .data-input-container {
    input {
      border: 1px solid $quaternary-color;
      border-radius: 3px;
      padding: 5px;
      font-size: 14px;
      color: $secondary-color;
      font-weight: 500;
    }

    &.data-error {
      input {
        border: 2px solid red;
        color: red;
      }
    }
  }

  .theme--light.v-input input,
  .theme--light.v-input textarea,
  .theme--light.v-select .v-select__selection--comma {
    color: $secondary-color;
    font-weight: 500;
  }
}
</style>
