<template>
  <v-container fluid id="indexProducts">
    <v-row no-gutters class="my-12">
      <v-col cols="12" class="mt-8 pt-2">
        <div class="container-button d-flex">
          <span class="subtitle-1 font-weight-medium quaternary--text"
            >Adicionar Produto</span
          >
          <v-btn
            fab
            dark
            small
            color="primary"
            class="ml-4"
            @click="newProduct()"
          >
            <v-icon size="20" color="white">fas fa-plus</v-icon>
          </v-btn>
        </div>
        <v-card class="ecotrace-card" cols="12">
          <v-card-title>
            Pesquisa de produtos
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row no-gutters>
                <v-col cols="3" class="px-3">
                  <v-text-field
                    v-model="searchData.name"
                    label="Nome do produto"
                    solo
                    flat
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="px-3">
                  <v-text-field
                    v-model="searchData.description"
                    label="Descrição"
                    solo
                    flat
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="px-3">
                  <v-text-field
                    v-model="searchData.language"
                    label="Linguagem do produto"
                    solo
                    flat
                  ></v-text-field>
                </v-col>
                <v-col cols="3" class="px-3">
                  <v-text-field
                    v-model="searchData.sku"
                    label="SKU"
                    solo
                    flat
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col v-if="isEcoAdmin" cols="3" class="px-3">
                  <v-autocomplete
                    v-model="searchData.group_id"
                    :items="industryGroups.data"
                    item-text="name"
                    clearable
                    item-value="id"
                    solo
                    flat
                    label="Grupo da Indústria"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <Button
              v-show="false"
              size="medium"
              text="Ajuda?"
              justText
              color="secondary underlined"
            />
            <Button
              @do="clearSearchbleFields"
              size="medium"
              class="mx-8"
              text="Limpar"
              color="quaternary"
            />
            <Button
              @do="searchProducts(1)"
              size="medium"
              class="mr-3"
              text="Filtrar"
              color="secondary"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class="mb-5">
      <v-col cols="12">
        <v-data-table
          :loading="loadingProducts"
          loading-text="Carregando..."
          :headers="headers"
          :items="products.data"
          expand-icon="fas fa-chevron-down"
          hide-default-footer
          class="ecotrace-data-table"
        >
          <template v-slot:item.details="{ item }">
            <Button
              id="teste-btn"
              size="small"
              text="ver"
              color="secondary"
              @do="editProduct(item.id)"
            />
          </template>

          <template v-slot:item.delete="{ item }">
            <v-btn
              text
              icon
              color="error"
              @click="openDeleteDialog(item.id, item.name)"
            >
              <v-icon size="25">far fa-times-circle</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <div class="mb-12">
        <Pagination
          :pagination="products.pagination"
          :disabled="false"
          @change-page="searchProducts"
        />
      </div>
    </v-row>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card class="pa-4">
        <v-card-title class="headline" color="error"
          >Deletar produto</v-card-title
        >
        <v-card-text>
          <span class="subtitle-1"
            >Você tem certeza que deseja deletar o produto
            <b v-if="deleteProduct.name">{{ deleteProduct.name }}?</b></span
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="quaternary" dark @click="deleteDialog = !deleteDialog"
            >Não</v-btn
          >
          <v-btn
            color="primary"
            :loading="loadingDeleteModal"
            @click="confirmDeleteProduct"
            >Sim</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      top
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn dark text @click.native="snackbar.show = false" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import appApi from '@/plugins/api';
import Button from '@/components/buttons/Button';
import Pagination from '@/components/Pagination';

export default {
  components: {
    Button,
    Pagination,
  },
  data() {
    return {
      searchData: {
        page: 1,
        perPage: 10,
      },
      headers: [
        { text: 'Nomes', value: 'name' },
        { text: 'SKU', value: 'sku' },
        { text: 'Linguagem', value: 'language' },
        { text: 'Vencimento', value: 'due_days' },
        { text: 'Detalhes', value: 'details', sortable: false },
        { text: 'Excluir', value: 'delete', sortable: false },
      ],
      products: {},
      isEcoAdmin: false,
      industryGroups: {},
      snackbar: {},
      loadingProducts: null,
      deleteProduct: {},
      deleteDialog: false,
      loadingDeleteModal: false,
    };
  },
  mounted: async function() {
    const roles = await this.$auth.user().roles;
    this.isEcoAdmin = roles.some((r) => r.name === 'eco_admin');
    if (this.isEcoAdmin) {
      appApi.getIndustryGroups().then((result) => {
        this.industryGroups = result.data;
      });
    }

    if (this.$route.query.page) {
      this.searchData['page'] = this.$route.query.page;
    } else {
      this.searchData['page'] = 1;
    }
    if (this.$route.query.group_id) {
      this.searchData['group_id'] = this.$route.query.group_id;
    }
    if (this.$route.query.name) {
      this.searchData['name'] = this.$route.query.name;
    }
    if (this.$route.query.description) {
      this.searchData['description'] = this.$route.query.description;
    }
    if (this.$route.query.language) {
      this.searchData['language'] = this.$route.query.language;
    }
    if (this.$route.query.sku) {
      this.searchData['sku'] = this.$route.query.sku;
    }

    await this.getProducts(this.searchData);
  },
  methods: {
    clearSearchbleFields() {
      this.searchData = {};
      this.$router.push({ name: 'Products' });
    },

    openDeleteDialog(id, name) {
      this.deleteProduct['id'] = id;
      this.deleteProduct['name'] = name;
      this.deleteDialog = !this.deleteDialog;
    },

    confirmDeleteProduct() {
      this.loadingDeleteModal = !this.loadingDeleteModal;
      appApi
        .deleteProduct(this.deleteProduct.id)
        .then(() => {
          this.snackbar = {
            show: true,
            text: 'Usuário deletado com sucesso',
            color: 'primary',
          };
          this.getProducts(this.searchData);
        })
        .catch((error) => {
          this.snackbar = {
            show: true,
            text: 'Erro ao tentar deletar o usuário',
            color: 'error',
          };
        })
        .finally(() => {
          this.loadingDeleteModal = !this.loadingDeleteModal;
          this.deleteDialog = !this.deleteDialog;
        });
    },

    searchProducts(page) {
      let data = { page: page ? page : 1 };

      if (this.searchData.name) {
        data['name'] = this.searchData.name;
      }
      if (this.searchData.group_id) {
        data['group_id'] = this.searchData.group_id;
      }

      if (this.searchData.description) {
        data['description'] = this.searchData.description;
      }

      if (this.searchData.language) {
        data['language'] = this.searchData.language;
      }

      if (this.searchData.sku) {
        data['sku'] = this.searchData.sku;
      }

      this.$router.push({ name: 'Products', query: data });
    },
    getProducts(searchData) {
      this.loadingProducts = true;
      appApi
        .getProducts(searchData)
        .then((result) => {
          this.products = result.data;
          this.loadingProducts = false;
        })
        .catch((error) => {});
    },

    editProduct(item) {
      this.$router.push({ name: 'ProductEdit', params: { id: item } });
    },

    newProduct() {
      this.$router.push({ name: 'ProductNew' });
    },
  },
};
</script>

<style lang="scss" scoped>
#indexProducts::v-deep {
  .container-button {
    position: absolute;
    align-items: center;
    right: 23px;
    top: 25px;
  }

  .ecotrace-data-table {
    .v-data-table-header {
      .text-start.sortable {
        span {
          margin-left: 24px;
        }
      }
    }
  }
}
</style>
