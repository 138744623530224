<template>
  <v-container fluid id="indexUsers">
    <v-col>
      <v-row no-gutters class="my-12">
        <v-col cols="12" class="pt-2">
          <div class="container-button d-flex">
            <span class="subtitle-1 font-weight-medium quaternary--text"
              >Adicionar usuário</span
            >
            <v-btn
              fab
              dark
              small
              color="primary"
              class="ml-4"
              @click="newUser()"
            >
              <v-icon size="20" color="white">fas fa-plus</v-icon>
            </v-btn>
          </div>
          <v-card class="ecotrace-card" cols="12">
            <v-card-title>
              Pesquisa de usuários
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-row no-gutters>
                  <v-col cols="4" class="px-4">
                    <v-text-field
                      v-model="searchData.name"
                      label="Nome do usuário"
                      solo
                      flat
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="px-4">
                    <v-text-field
                      v-model="searchData.email"
                      label="Email"
                      solo
                      flat
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="px-4">
                    <v-text-field
                      v-model="searchData.phone"
                      label="Telefone"
                      solo
                      flat
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <Button
                v-show="false"
                size="medium"
                text="Ajuda?"
                justText
                color="secondary underlined"
              />
              <Button
                @do="clearSearchbleFields"
                size="medium"
                class="mx-8"
                text="Limpar"
                color="quaternary"
              />
              <Button
                @do="searchUsers(1)"
                size="medium"
                class="mr-3"
                text="Filtrar"
                color="secondary"
              />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-5">
        <v-col cols="12">
          <v-data-table
            :loading="loadingUsers"
            loading-text="Carregando..."
            :headers="headers"
            :items="users.data"
            expand-icon="fas fa-chevron-down"
            hide-default-footer
            class="ecotrace-data-table"
          >
            <template v-slot:item.phone="{ item }">
              {{ item.phone | formatPhone }}
            </template>

            <template v-slot:item.details="{ item }">
              <Button
                id="teste-btn"
                size="small"
                text="ver"
                color="secondary"
                @do="editUser(item.id)"
              />
            </template>

            <template v-slot:item.delete="{ item }">
              <v-btn
                text
                icon
                color="error"
                @click="openDeleteDialog(item.id, item.name)"
              >
                <v-icon size="25">far fa-times-circle</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <div>
          <Pagination
            :pagination="users.pagination"
            :disabled="false"
            @change-page="searchUsers"
          />
        </div>
      </v-row>
    </v-col>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card class="pa-4">
        <v-card-title class="headline" color="error"
          >Deletar usuário</v-card-title
        >
        <v-card-text>
          <span class="subtitle-1"
            >Você tem certeza que deseja deletar o usuário
            <b v-if="deleteUser.name">{{ deleteUser.name }}?</b></span
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="quaternary" dark @click="deleteDialog = !deleteDialog"
            >Não</v-btn
          >
          <v-btn
            color="primary"
            :loading="loadingDeleteModal"
            @click="confirmDeleteUser(deleteUser.id)"
            >Sim</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      top
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn dark text @click.native="snackbar.show = false" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import appApi from '@/plugins/api';
import Button from '@/components/buttons/Button';
import Pagination from '@/components/Pagination';

export default {
  components: {
    Button,
    Pagination,
  },
  data() {
    return {
      searchData: {
        page: 1,
      },
      headers: [
        { text: 'Usuário', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Telefone', value: 'phone' },
        { text: 'Detalhes', value: 'details', sortable: false },
        { text: 'Excluir', value: 'delete', sortable: false },
      ],
      users: {},
      loadingUsers: false,
      deleteDialog: false,
      deleteUser: {
        id: null,
        name: null,
      },
      loadingDeleteModal: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
    };
  },
  mounted: async function() {
    if (this.$route.query.page) {
      this.searchData['page'] = this.$route.query.page;
    } else {
      this.searchData['page'] = 1;
    }
    if (this.$route.query.name) {
      this.searchData['name'] = this.$route.query.name;
    }
    if (this.$route.query.email) {
      this.searchData['email'] = this.$route.query.email;
    }
    if (this.$route.query.phone) {
      this.searchData['phone'] = this.$route.query.phone;
    }

    await this.getUsers(this.searchData);
  },
  methods: {
    clearSearchbleFields() {
      this.searchData = {};
      this.$router.push({ name: 'Users' });
    },
    searchUsers(page) {
      let data = { page: page ? page : 1 };

      if (this.searchData.name) {
        data['name'] = this.searchData.name;
      }

      if (this.searchData.email) {
        data['email'] = this.searchData.email;
      }

      if (this.searchData.phone) {
        data['phone'] = this.searchData.phone.replace(/\D/g, '');
      }

      this.$router.push({ name: 'Users', query: data });
    },

    getUsers(searchData) {
      this.loadingUsers = true;
      appApi
        .getUsers(searchData)
        .then((result) => {
          this.users = result.data;
          this.loadingUsers = false;
        })
        .catch((error) => {});
    },

    newUser() {
      this.$router.push({ name: 'UserNew' });
    },

    editUser(item) {
      this.$router.push({ name: 'UserEdit', params: { id: item } });
    },

    openDeleteDialog(id, name) {
      this.deleteUser.id = id;
      this.deleteUser.name = name;
      this.deleteDialog = !this.deleteDialog;
    },

    confirmDeleteUser(id) {
      this.loadingDeleteModal = !this.loadingDeleteModal;
      appApi
        .deleteUser(id)
        .then((result) => {
          this.loadingDeleteModal = !this.loadingDeleteModal;
          this.deleteDialog = !this.deleteDialog;
          this.getUsers(this.searchData);
          this.snackbar = {
            show: true,
            text: 'Usuário deletado com sucesso',
            color: 'primary',
          };
        })
        .catch((error) => {
          this.loadingDeleteModal = !this.loadingDeleteModal;
          this.deleteDialog = !this.deleteDialog;
          this.getUsers(this.searchData);
          console.log(error);
          this.snackbar = {
            show: true,
            text: 'Erro ao tentar deletar o usuário',
            color: 'error',
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#indexUsers::v-deep {
  .container-button {
    position: absolute;
    align-items: center;
    right: 23px;
    top: 25px;
  }

  .ecotrace-data-table {
    .v-data-table-header {
      .text-start.sortable {
        span {
          margin-left: 24px;
        }
      }
    }
  }
}
</style>
