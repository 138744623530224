<template>
  <v-container fluid id="indexIndustria">
    <v-col>
      <v-row no-gutters class="my-12">
        <v-col cols="12" class="pt-2">
          <div class="container-button d-flex">
            <span class="subtitle-1 font-weight-medium quaternary--text"
              >Adicionar Industria</span
            >
            <v-btn
              fab
              dark
              small
              color="primary"
              class="ml-4"
              @click="newIndustry()"
            >
              <v-icon size="20" color="white">fas fa-plus</v-icon>
            </v-btn>
          </div>
          <v-card class="ecotrace-card" cols="12">
            <v-card-title>
              Pesquisa de industrias
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-row no-gutters>
                  <v-col cols="4" class="px-4">
                    <v-text-field
                      v-model="searchData.name"
                      label="Nome da indústria"
                      solo
                      flat
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" class="px-4">
                    <v-text-field
                      v-model="searchData.document_number"
                      label="CNPJ Indústria"
                      solo
                      flat
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" class="px-4">
                    <v-select
                      v-model="searchData.state"
                      :items="states.data"
                      item-text="initials"
                      :loading="loadingStates"
                      item-value="id"
                      clearable
                      label="UF"
                      @change="getCities()"
                      append-icon="fas fa-chevron-down"
                      flat
                      solo
                    ></v-select>
                  </v-col>
                  <v-col cols="3" class="px-4">
                    <v-select
                      v-model="searchData.city"
                      :items="cities"
                      item-text="name"
                      item-value="id"
                      :loading="loadingCities"
                      clearable
                      :disabled="!searchData.state"
                      label="Cidade"
                      append-icon="fas fa-chevron-down"
                      flat
                      solo
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-end">
              <Button
                v-show="false"
                size="medium"
                text="Ajuda?"
                justText
                color="secondary underlined"
              />
              <Button
                @do="clearSearchbleFields"
                size="medium"
                class="mx-8"
                text="Limpar"
                color="quaternary"
              />
              <Button
                @do="searchIndustries(1)"
                size="medium"
                class="mr-3"
                text="Filtrar"
                color="secondary"
              />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters class="mb-5">
        <v-col cols="12">
          <v-data-table
            :loading="loadingIndustries"
            loading-text="Carregando..."
            :headers="headers"
            :items="industries.data"
            expand-icon="fas fa-chevron-down"
            hide-default-footer
            class="ecotrace-data-table"
          >
            <template v-slot:item.document_number="{ item }">
              {{ item.document_number | formatCpfCnpj }}
            </template>

            <template v-slot:item.details="{ item }">
              <Button
                id="teste-btn"
                size="small"
                text="ver"
                color="secondary"
                @do="editIndustry(item.id)"
              />
            </template>

            <template v-slot:item.delete="{ item }">
              <v-btn
                text
                icon
                color="error"
                @click="openDeleteDialog(item.id, item.name)"
              >
                <v-icon size="25">far fa-times-circle</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <div>
          <Pagination
            :pagination="industries.pagination"
            :disabled="false"
            @change-page="searchIndustries"
          />
        </div>
      </v-row>
    </v-col>

    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card class="pa-4">
        <v-card-title class="headline" color="error"
          >Deletar indústria</v-card-title
        >
        <v-card-text>
          <span class="subtitle-1"
            >Você tem certeza que deseja deletar a industria
            <b v-if="deleteIndustry.name">{{ deleteIndustry.name }}?</b></span
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="quaternary" dark @click="deleteDialog = !deleteDialog"
            >Não</v-btn
          >
          <v-btn
            color="primary"
            :loading="loadingDeleteModal"
            @click="confirmDeleteIndustry(deleteIndustry.id)"
            >Sim</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      top
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn dark text @click.native="snackbar.show = false" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import appApi from '@/plugins/api';
import Button from '@/components/buttons/Button';
import Pagination from '@/components/Pagination';

export default {
  components: {
    Button,
    Pagination,
  },
  data() {
    return {
      searchData: {
        page: 1,
      },
      turnos: [
        { id: 1, name: 'Manhã' },
        { id: 2, name: 'Tarde' },
        { id: 3, name: 'Noite' },
      ],
      headers: [
        { text: 'Industria', value: 'name' },
        { text: 'CNPJ', value: 'document_number' },
        { text: 'UF', value: 'address.state_name' },
        { text: 'Município', value: 'address.city_name' },
        { text: 'Detalhes', value: 'details', sortable: false },
        { text: 'Excluir', value: 'delete', sortable: false },
      ],
      industries: {},
      states: {},
      cities: [],
      selectCity: true,
      loadingCities: false,
      loadingStates: false,
      loadingIndustries: false,
      deleteDialog: false,
      deleteIndustry: {
        id: null,
        name: null,
      },
      loadingDeleteModal: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
    };
  },
  mounted: async function() {
    if (this.$route.query.page) {
      this.searchData['page'] = this.$route.query.page;
    } else {
      this.searchData['page'] = 1;
    }
    if (this.$route.query.name) {
      this.searchData['name'] = this.$route.query.name;
    }
    if (this.$route.query.document_number) {
      this.searchData['document_number'] = this.$route.query.document_number;
    }
    if (this.$route.query.state) {
      this.searchData['state'] = this.$route.query.state;
      this.getCities();
    }
    if (this.$route.query.city) {
      this.searchData['city'] = this.$route.query.city;
    }

    this.getStates();

    await this.getIndustries(this.searchData);
  },
  methods: {
    clearSearchbleFields() {
      this.searchData = {};
      this.$router.push({ name: 'Industries' });
    },

    searchIndustries(page) {
      let data = { page: page ? page : 1 };

      if (this.searchData.name) {
        data['name'] = this.searchData.name;
      }

      if (this.searchData.document_number) {
        data['document_number'] = this.searchData.document_number.replace(
          /\D/g,
          ''
        );
      }

      if (this.searchData.state) {
        data['state'] = this.searchData.state;
      }

      if (this.searchData.city) {
        data['city'] = this.searchData.city;
      }

      this.$router.push({ name: 'Industries', query: data });
    },

    getIndustries(searchData) {
      this.loadingIndustries = true;
      appApi
        .getIndustries(searchData)
        .then((result) => {
          this.industries = result.data;
          this.loadingIndustries = false;
        })
        .catch((error) => {});
    },

    getStates() {
      this.loadingStates = true;
      appApi
        .getStates()
        .then((result) => {
          this.states = result.data;
          this.loadingStates = false;
        })
        .catch((error) => {
          console.log('Erro ao tentar listar estados');
        });
    },
    getCities() {
      if (this.searchData.state) {
        this.loadingCities = true;
        appApi
          .getCities({
            stateId: this.searchData.state,
          })
          .then((result) => {
            this.cities = result.data.data;
            this.loadingCities = false;
          });
      }
    },

    editIndustry(item) {
      this.$router.push({ name: 'IndustryEdit', params: { id: item } });
    },

    newIndustry() {
      this.$router.push({ name: 'IndustryNew' });
    },

    openDeleteDialog(id, name) {
      this.deleteIndustry.id = id;
      this.deleteIndustry.name = name;
      this.deleteDialog = !this.deleteDialog;
    },

    confirmDeleteIndustry(id) {
      this.loadingDeleteModal = !this.loadingDeleteModal;
      appApi
        .deleteIndustry(id)
        .then((result) => {
          this.loadingDeleteModal = !this.loadingDeleteModal;
          this.deleteDialog = !this.deleteDialog;
          this.getIndustries(this.searchData);
          this.snackbar = {
            show: true,
            text: 'Indústria deletada com sucesso',
            color: 'primary',
          };
        })
        .catch((error) => {
          this.loadingDeleteModal = !this.loadingDeleteModal;
          this.deleteDialog = !this.deleteDialog;
          this.getIndustries(this.searchData);
          console.log(error);
          this.snackbar = {
            show: true,
            text: 'Erro ao tentar deletar a industria',
            color: 'error',
          };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#indexIndustria::v-deep {
  .container-button {
    position: absolute;
    align-items: center;
    right: 23px;
    top: 25px;
  }

  .ecotrace-data-table {
    .v-data-table-header {
      .text-start.sortable {
        span {
          margin-left: 24px;
        }
      }
    }
  }
}
</style>
