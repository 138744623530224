<template>
  <v-container fluid id="industryDetail">
    <v-col>
      <v-row no-gutters class="pb-4 mt-0">
        <v-col>
          <v-btn fab text small @click="goBack" class="ma-n1">
            <v-icon color="secondary" size="30">fas fa-arrow-left</v-icon>
          </v-btn>
          <div class="container-form-buttons">
            <Button
              class="mr-8"
              size="medium"
              text="Voltar"
              color="quaternary"
              @do="goBack"
            />
            <Button
              size="medium"
              text="Salvar"
              :loading="loadingSaveProduct"
              color="primary"
              @do="saveProduct"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="my-12" justify="center" v-if="!loadingProduct">
        <v-col cols="8" class="pt-2">
          <v-card class="ecotrace-card" cols="12">
            <v-card-title
              >{{
                $route.params.id ? 'Editar ' : 'Cadastrar novo'
              }}
              produto</v-card-title
            >
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col v-if="isEcoAdmin" cols="12">
                    <v-autocomplete
                      v-model="product.group_id"
                      :items="industryGroups.data"
                      :error="validation.group_id.error"
                      :error-messages="validation.group_id.message"
                      @change="clearError('group_id')"
                      item-text="name"
                      item-value="id"
                      outlined
                      label="Grupo da Indústria"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="product.sku"
                      :error="validation.sku.error"
                      :error-messages="validation.sku.message"
                      @keyup="clearError('sku')"
                      label="SKU"
                      color="secondary"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="product.name"
                      :error="validation.name.error"
                      :error-messages="validation.name.message"
                      @keyup="clearError('name')"
                      label="Nome"
                      color="secondary"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="product.language"
                      :items="languageList"
                      label="Idioma"
                      :error="validation.language.error"
                      :error-messages="validation.language.message"
                      @change="clearError('language')"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="product.description"
                      :error="validation.description.error"
                      :error-messages="validation.description.message"
                      label="Descrição"
                      @keyup="clearError('description')"
                      color="secondary"
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="product.due_days"
                      label="Prazo de validade"
                      color="secondary"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="product.gtin"
                      :error="validation.gtin.error"
                      :error-messages="validation.gtin.message"
                      @keyup="clearError('gtin')"
                      label="GTIN"
                      color="secondary"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="product.product_part"
                      :items="productParts"
                      item-text="description"
                      item-value="id"
                      color="secondary"
                      label="Corte do produto"
                      outlined
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="6">
                    <v-autocomplete
                      v-model="product.meals"
                      :items="meals"
                      item-text="description"
                      item-value="type"
                      outlined
                      filled
                      flat
                      chips
                      multiple
                      solo
                      deletable-chips
                      label="Tipo de preparo"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="ecotrace-card" style="min-height: 100%">
            <v-row no-gutters>
              <v-col cols="12" class="pt-2">
                <v-file-input
                  @change="getPreviewImage"
                  label="Foto do produto"
                  accept="image/png, image/jpeg, image/bmp"
                  filled
                  prepend-icon="mdi-camera"
                  outlined
                  chips
                ></v-file-input>
                <div v-if="product.image_url" class="text-center">
                  <v-btn @click="deleteImage" small>Excluir Imagem</v-btn>
                </div>
              </v-col>
              <v-col v-if="previewImage">
                <v-img
                  :src="previewImage"
                  max-height="250"
                  contain
                  alt="Logo"
                  style="transform: translateY(50%)"
                ></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" v-else>
        <v-progress-circular
          color="#315ca7"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </v-col>

    <v-snackbar
      :timeout="2000"
      top
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn dark text @click.native="snackbar.show = false" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import appApi from '@/plugins/api';
import Button from '@/components/buttons/Button';

import axios from 'axios';

const instance = axios.create();

export default {
  components: {
    Button,
  },
  data() {
    return {
      snackbar: {},
      product: {
        name: '',
        group_id: null,
        product_type_id: null,
        description: '',
        sku: '',
        gtin: '',
        product_part: '',
        product_part_name: '',
        language: '',
        due_days: '',
        meals: [],
        image_url : null
      },
      isEcoAdmin: false,
      validation: {
        name: {
          error: false,
          message: '',
        },
        description: {
          error: false,
          message: '',
        },
        sku: {
          error: false,
          message: '',
        },
        gtin: {
          error: false,
          message: '',
        },
        language: {
          error: false,
          message: '',
        },
        group_id: {
          error: false,
          message: '',
        },
      },
      industryGroups: [],
      meals: [
        { type: 1, description: 'Cozinhar' },
        { type: 2, description: 'Grelhar' },
      ],
      productParts: [
        { id: 1, description: 'Pescoço' },
        { id: 2, description: 'Acém' },
        { id: 3, description: 'Peito e peito com osso' },
        { id: 4, description: 'Paleta ou miolo de paleta' },
        { id: 5, description: 'Fraldinha' },
        { id: 6, description: 'Ponta de agulha' },
        { id: 7, description: 'Filé mignon' },
        { id: 8, description: 'Filé de Costela' },
        { id: 9, description: 'Contra filé' },
        { id: 10, description: 'Capa de filé' },
        { id: 11, description: 'Alcatra' },
        { id: 12, description: 'Patinho' },
        { id: 13, description: 'Coxão duro' },
        { id: 14, description: 'Coxão mole' },
        { id: 15, description: 'Lagarto' },
        { id: 16, description: 'Músculo dianteiro' },
        { id: 17, description: 'Músculo traseiro' },
        { id: 18, description: 'Aba do filé' },
        { id: 19, description: 'Maminha da alcatra' },
        { id: 20, description: 'Picanha' },
        { id: 21, description: 'Cupim' },
        { id: 100, description: 'Miúdos' },
      ],
      industries: [],
      user_groupId: {},
      loadingProduct: false,
      loadingSaveProduct: false,
      showPassIcon: false,
      showGroupdField: false,
      previewImage: null,
      userPicture: null,
      languageList: ['pt-br', 'zh-cn'],
    };
  },

  async mounted() {
    await this.getInitialData();

    if (this.$route.params.id) {
      this.loadingProduct = true;
      appApi
        .getProduct(this.$route.params.id)
        .then(({ data: product }) => {
          const { meals = [] } = product;

          this.product = {
            group_id: product.group_id,
            name: product.name,
            description: product.description,
            sku: product.sku,
            gtin: product.gtin,
            product_part: +product.product_part,
            language: product.language,
            due_days: product.due_days,
            meals: meals.map((p) => +p.type),
            image_url : product.image_url
          };

          this.previewImage = product.image_url;
        })
        .finally(() => {
          this.loadingProduct = false;
        });
    }
  },

  methods: {
    goBack() {
      window.history.back();
    },

    deleteImage() {
      this.$confirm("Tem certeza que deseja excluir imagem?")
        .then(confirm => {
          if(confirm) {
            appApi.deleteProductImage(this.$route.params.id)
              .then(res => {
                this.previewImage = null
                this.product.image_url = null
                this.snackbar = {
                  show: true,
                  text: 'Imagem excluída com sucesso',
                  color: 'success',
                };
              })
          }
        })
    },

    async getInitialData() {
      const roles = await this.$auth.user().roles;
      this.isEcoAdmin = roles.some((r) => r.name === 'eco_admin');

      if (this.isEcoAdmin) {
        appApi.getIndustryGroups().then((result) => {
          this.industryGroups = result.data;
        });
      }
    },

    clearError(field) {
      this.validation[field].error = false;
      this.validation[field].message = '';
    },

    getPreviewImage(fileInput) {
      if (fileInput) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(fileInput);
        this.productImage = fileInput;
      } else {
        this.previewImage = null;
        this.productImage = null;
      }
    },

    async saveInitialProductImageData(prodId) {
      if (this.productImage) {
        let formData = new FormData();

        const { data } = await appApi.saveProductImage(prodId, {
          image: { filename: this.productImage.name },
        });

        for (let key in data.fields) {
          formData.append(key, data.fields[key]);
        }

        formData.append('file', this.productImage);

        instance
          .post(data.url, formData, {
            headers: formData,
          })
          .catch((e) => console.log(e));
      }

      return prodId;
    },

    selectRole() {
      if (this.user.roles == 1) this.showGroupdField = true;
      else this.showGroupdField = false;
    },

    removeChips(item) {
      const index = this.roles.indexOf(item.name);
      if (index >= 0) this.roles.splice(index, 1);
    },

    handleSuccess() {
      this.snackbar = {
        show: true,
        text: 'Produto salvo com sucesso',
        color: 'success',
      };

      this.loadingSaveProduct = false;

      setTimeout(() => {
        this.$router.push({ name: 'Products' });
      }, 2000);
    },

    handleError(err) {
      this.loadingSaveProduct = false;
      if (err.response && err.response.status == 400) {
        err.response.data.forEach((element) => {
          this.validation[element.field].error = true;
          this.validation[element.field].message = element.message;
        });
      }

      this.snackbar = {
        show: true,
        text: 'Erro ao salvar produto',
        color: 'error',
      };

      console.log(err);
    },

    async saveProduct() {
      this.loadingSaveProduct = true;

      let productPart = this.productParts.find(
        (e) => e.id == this.product.product_part
      )

      const prodData = {
        group_id: this.product.group_id,
        name: this.product.name,
        description: this.product.description,
        sku: this.product.sku,
        gtin: this.product.gtin,
        product_part: this.product.product_part,
        
        product_part_name: productPart ? productPart.description : null,

        language: this.product.language,
        due_days: this.product.due_days,
        meals: this.product.meals,
      };

      if (!this.$route.params.id) {
        appApi
          .postProduct(prodData)
          .then(({ data }) =>
            this.saveInitialProductImageData(data.id).then(this.handleSuccess)
          )
          // .then((id) => appApi.postProductDynamo(id))
          .catch(this.handleError);
      } else {
        this.saveInitialProductImageData(this.$route.params.id)
          .then(() =>
            appApi
              .putProduct(this.$route.params.id, prodData)
              .then(this.handleSuccess)
          )
          // .then(() => appApi.postProductDynamo(this.$route.params.id))
          .catch(this.handleError);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#industryDetail::v-deep {
  .container-form-buttons {
    position: absolute;
    display: flex;
    right: 30px;
  }

  .theme--light.v-input input,
  .theme--light.v-input textarea,
  .theme--light.v-select .v-select__selection--comma {
    color: $secondary-color;
    font-weight: 500;
  }

  .theme--light.v-chip:not(.v-chip--active) {
    border-radius: 5px;
    background-color: #ebf3ff;
    color: #315ca7;
  }

  .theme--light.v-chip:not(.v-chip--active) .theme--light.v-icon {
    color: #315ca7;
  }

  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: transparent;
  }

  .v-file-input.v-text-field--filled:not(.v-text-field--single-line)
    .v-file-input__text {
    padding-top: 5px;
  }
}
</style>
