<template>
  <div v-if="pagination && pagination.lastPage > 1">
    <v-pagination
      v-model="pagination.page"
      :length="pagination.lastPage"
      :total-visible="7"
      :disabled="false"
      v-on:input="$emit('change-page', pagination.page)"
      prev-icon="fas fa-chevron-left"
      next-icon="fas fa-chevron-right"
    />
  </div>
</template>

<script>
export default {
  props: {
    pagination: Object,
    disabled: Boolean
  }
};
</script>
<style scoped lang="css">
>>> .v-pagination .v-pagination__item {
  /* margin: 0 -; */
  -webkit-box-shadow: none;
  min-width: 44px;
  min-height: 44px;
  border-radius: 0px;
  font-size: 16px;
}

>>> .v-pagination li:not(:first-child):not(:last-child) {
  margin: 0 -5px;
}

>>> .v-pagination li:first-child .v-pagination__navigation {
  border-radius: 50%;
  min-width: 44px;
  min-height: 44px;
  -webkit-box-shadow: none;
}

>>> .v-pagination li:first-child .v-pagination__navigation .v-icon {
  margin-left: -5px;
  font-size: 16px;
}

>>> .v-pagination li:last-child .v-pagination__navigation {
  border-radius: 50%;
  min-width: 44px;
  min-height: 44px;
  -webkit-box-shadow: none;
}

>>> .v-pagination li:last-child .v-pagination__navigation .v-icon {
  margin-left: 5px;
  font-size: 16px;
}

>>> .v-pagination li:nth-child(2) .v-pagination__item {
  border-radius: 50% 0 0 50%;
}
>>> .v-pagination li:nth-last-child(-n + 2) .v-pagination__item {
  border-radius: 0 50% 50% 0;
}
</style>
