<template>
  <v-app id="loginPage">
    <v-content>
      <v-container class="fill-height" fluid>
        <v-row no-gutters>
          <v-col
            id="info-box"
            cols="12"
            lg="4"
            order="last order-lg-first"
            class="d-flex flex-column justify-space-between"
          >
            <v-row no-gutters>
              <v-col class="my-10 container-logo-txt text-center">
                <v-avatar color="indigo" size="100">
                  <v-img
                    src="/static/logo-exemplo.png"
                    contain
                    class="ml-n1"
                    width="90"
                    height="90"
                  ></v-img>
                </v-avatar>
                <span
                  class="font-italic font-weight-regular text-uppercase headline ml-8"
                  style="white-space: nowrap;"
                  >TRACE BEEF B2B</span
                >
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-row no-gutters class="justify-center">
              <div class="text-container">
                <h1 max-width="440" class="text-uppercase display-2">
                  Seja bem-Vindo!
                </h1>
                <p class="subtitle-1">
                  A Ecotrace é um sistema de rastreabilidade único por sua
                  confiança e transparência nos dados através da tecnologia
                  Blockchain.
                </p>
                <!-- <p class="headline font-italic mt-5 mb-8">
                  Ainda não tem uma conta?
                </p> -->
                <!-- <Button size="big" text="quero contratar agora" color="secondary" /> -->
              </div>
            </v-row>
            <v-spacer></v-spacer>
            <v-row no-gutters>
              <v-col class="text-center redes-sociais">
                <span class="subtitle-1 font-italic">Siga nos </span>
                <v-btn
                  text
                  icon
                  color="white"
                  @click="
                    socialLinks(
                      'https://www.facebook.com/ecotracesolutions/',
                      '_blank'
                    )
                  "
                >
                  <v-icon size="16">fab fa-facebook-f</v-icon>
                </v-btn>
                <v-btn
                  text
                  icon
                  color="white"
                  @click="
                    socialLinks(
                      'https://www.linkedin.com/company/ecotrace-solutions/',
                      '_blank'
                    )
                  "
                >
                  <v-icon size="16">fab fa-linkedin-in</v-icon>
                </v-btn>
                <v-btn
                  text
                  icon
                  color="white"
                  @click="
                    socialLinks(
                      'https://instagram.com/ecotracesolutions?igshid=1pbagzhs2wylj',
                      '_blank'
                    )
                  "
                >
                  <v-icon size="16">fab fa-instagram</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            id="login-box"
            cols="12"
            lg="8"
            order="first order-lg-last"
            class="d-flex flex-column justify-center"
          >
            <!-- <Button
              text="Precisa de ajuda?"
              justText
              color="secondary"
              absolute
              top
              right
              class="mt-7"
            /> -->
            <v-spacer></v-spacer>
            <v-row
              no-gutters
              class="align-self-center justify-center form-container"
            >
              <v-form class="pa-8" onSubmit="return false;">
                <div class="container-form-header mb-10 text-center">
                  <v-img
                    src="/static/logo-retang.png"
                    width="188"
                    height="auto"
                    class="ma-auto"
                  ></v-img>
                  <h2
                    class="color-secondary text-uppercase display-2 font-weight-medium my-12"
                  >
                    acesso ao painel
                  </h2>
                </div>

                <v-alert
                  v-if="loginError"
                  :value="true"
                  color="error"
                  icon="warning"
                  outlined
                >
                  {{ loginMessageError }}
                </v-alert>

                <v-text-field
                  outlined
                  name="email"
                  label="Email"
                  type="email"
                  v-model="model.email"
                  prepend-inner-icon="far fa-user"
                ></v-text-field>

                <v-text-field
                  outlined
                  name="senha"
                  label="Senha"
                  type="password"
                  v-model="model.password"
                  prepend-inner-icon="lock"
                ></v-text-field>

                <div class="container-login-button text-center mt-6">
                  <Button
                    size="big"
                    type="submit"
                    text="Entrar no painel"
                    @do="login"
                    :loading="loading"
                    color="primary"
                  />
                  <hr class="mt-12" />
                  <Button
                    text="Esqueceu sua senha?"
                    justText
                    color="secondary underlined"
                    class="mt-7"
                    @do="showForgotPasswordDialog"
                  />
                </div>
              </v-form>
            </v-row>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>

        <v-dialog v-model="forgotPasswordDialog" scrollable width="800">
          <v-card>
            <v-card-title class="primary white--text" primary-title>
              Esqueci a senha
            </v-card-title>

            <v-card-text>
              <div v-if="inForgotForm">
                <v-row>
                  <v-col>
                    <p class="text-info headline text-center">
                      Digite seu e-mail ou seu telefone
                    </p>
                  </v-col>
                </v-row>

                <v-row v-show="alert.show" no-gutters justify="center">
                  <v-col class="mt-n5" cols="10">
                    <v-alert :type="alert.type">
                      {{ alert.message }}
                    </v-alert>
                  </v-col>
                </v-row>

                <v-col>
                  <v-text-field
                    v-model="forgotPassword.email"
                    :error="validation.email.error"
                    :error-messages="validation.email.message"
                    @keyup="
                      clearError('email');
                      clearError('phone');
                    "
                    :rules="[
                      (v) =>
                        v.trim().length == 0 ||
                        /.+@.+\..+/.test(v) === true ||
                        'Email precisa ser é válido',
                    ]"
                    label="Email"
                    color="secondary"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="forgotPassword.phone"
                    :error="validation.phone.error"
                    :error-messages="validation.phone.message"
                    @keyup="
                      clearError('email');
                      clearError('phone');
                    "
                    :rules="[
                      (v) =>
                        v.trim().length == 0 ||
                        (v.replace(/\D/g, '').length > 9 &&
                          v.replace(/\D/g, '').length < 12) ||
                        'Telefone precisa ser válido',
                    ]"
                    label="Telefone"
                    v-mask="['(##) #####-####', '(##) ####-####']"
                    color="secondary"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-row justify="center">
                  <v-col align="center" md="5" cols="12">
                    <Button
                      :loading="loadingForgotPassword"
                      text="Enviar"
                      size="big"
                      color="primary"
                      @do="resetPasswordRequest"
                    />
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row justify="center">
                  <v-col class="mt-8" cols="10">
                    <v-alert :type="alert.type">
                      {{ alert.message }}
                    </v-alert>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="forgotPasswordDialog = false">
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          persistent
          v-model="changePasswordDialog"
          scrollable
          width="800"
        >
          <v-card>
            <v-card-title class="primary white--text" primary-title>
              Mudança de senha
            </v-card-title>

            <v-card-text v-if="!loadingToken">
              <div v-if="tokenVerified">
                <v-row justify="center">
                  <v-col cols="10">
                    <v-alert type="info">
                      Digite sua nova senha e confirmação da mesma. A senha deve
                      ter no mínimo 6 caracteres.
                    </v-alert>
                  </v-col>
                </v-row>

                <v-col>
                  <v-text-field
                    :error="validation.password.error"
                    :error-messages="validation.password.message"
                    @keyup="clearError('password')"
                    v-model="changePassword.password"
                    label="Nova senha"
                    color="secondary"
                    outlined
                    type="password"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field
                    :error="validation.newPassword.error"
                    :error-messages="validation.newPassword.message"
                    @keyup="clearError('newPassword')"
                    v-model="changePassword.newPassword"
                    label="Confirme a nova senha"
                    color="secondary"
                    type="password"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-row justify="center">
                  <v-col align="center" md="5" cols="12">
                    <Button
                      size="big"
                      text="Enviar"
                      color="primary"
                      :loading="loadingChangePassword"
                      @do="postChangePassword"
                    />
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row justify="center">
                  <v-col cols="10">
                    <v-alert type="error">
                      Token expirado ou não encontrado.
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col align="center">
                    <Button
                      text="Fechar"
                      size="big"
                      color="secondary"
                      @do="sendToLoginPage"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-card-text v-else>
              <v-row>
                <v-col>
                  <p class="text-info headline text-center">
                    Verificando por favor aguarde
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col align="center">
                  <v-progress-circular
                    :width="3"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="sendToLoginPage">
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-snackbar
          :timeout="2000"
          top
          right
          :color="snackbar.color"
          v-model="snackbar.show"
        >
          {{ snackbar.text }}
          <v-btn dark text @click.native="snackbar.show = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-snackbar>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import Vue from 'vue';
import { watch } from 'fs';
import Button from '@/components/buttons/Button';
import { log } from 'util';

import appApi from '@/plugins/api';

export default {
  components: {
    Button,
  },
  data: () => ({
    loading: false,
    changePassword: {},
    loginError: false,
    passType: 'password',
    loadingChangePassword: false,
    passIcon: 'fa-eye-slash',
    loadingPostResetPassword: false,
    forgotPassword: {
      email: '',
      password: '',
      userEmail: '',
    },
    validation: {
      email: {
        error: false,
        message: '',
      },
      phone: {
        error: false,
        message: '',
      },
      password: {
        error: false,
        message: '',
      },
      newPassword: {
        error: false,
        message: '',
      },
    },
    snackbar: {},
    tokenVerified: false,
    inForgotForm: true,
    loadingToken: true,
    alert: {},
    changePasswordDialog: false,
    showForgotUserEmail: false,
    loadingForgotPassword: false,

    loginMessageError: '',
    forgotPasswordDialog: false,
    model: {
      email: '',
      password: '',
    },
  }),

  created() {
    setTimeout(() => {
      if (this.$auth.ready() && this.$auth.check()) {
        this.$router.push({ name: 'Rastreabilidade' });
      } else {
        if (localStorage.getItem('refresh_token')) {
          this.loading = true;
          this.$auth
            .login({
              headers: {
                Authorization:
                  'Bearer ' + localStorage.getItem('refresh_token'),
              },
              data: {},
              rememberMe: true,
              fetchUser: true,
            })
            .then((resp) => {
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      }
    }, 500);
  },

  mounted() {
    if (this.$route.query.token) {
      this.changePasswordDialog = true;
      this.loadingToken = true;
      appApi
        .getResetPassword(this.$route.query.token)
        .then((res) => {
          this.tokenVerified = true;
        })
        .catch((err) => {
          this.tokenVerified = false;
        })
        .finally(() => {
          this.loadingToken = false;
        });
    }
  },

  methods: {
    socialLinks(linkString, targetString) {
      window.open(linkString, targetString);
    },

    sendToLoginPage() {
      this.$router.push({ name: 'Login' });
    },

    postChangePassword() {
      this.loadingChangePassword = true;
      appApi
        .putResetPassword(this.$route.query.token, {
          password: this.changePassword.password,
          newPassword: this.changePassword.newPassword,
        })
        .then((res) => {
          this.snackbar = {
            show: true,
            text: 'Senha alterada com sucesso',
            color: 'success',
          };
          setTimeout(() => {
            this.model.email = res.data.email;
            this.model.password = this.changePassword.newPassword;
            this.login();
          }, 3000);
        })
        .catch(this.handleError)
        .finally(() => {
          this.loadingChangePassword = false;
        });
    },

    clearError(field) {
      this.validation[field].error = false;
      this.validation[field].message = '';
      this.alert.show = false;
    },

    handleSuccess(res) {
      this.inForgotForm = false;
      this.showForgotUserEmail = true;

      this.alert = {
        show: true,
        message: `E-mail de alteração de senha enviado para:
                    ${res.data.email}. Dentro de alguns instantes
                    você receberá o e-mail, caso não apareça em sua
                    caixa de entrada, verifique seu spam.`,
        type: 'success',
      };
    },

    handleError(err) {
      if (err.response && err.response.status == 400) {
        this.alert = {
          type: 'error',
          message:
            'Erro ao requisitar mudança de senha, preencha o campo email ou o campo telefone corretamente.',
          show: true,
        };
      }

      if (err.response && err.response.status == 400) {
        err.response.data.forEach((element) => {
          this.validation[element.field].error = true;
          this.validation[element.field].message = element.message;
        });
      }

      console.log(err);
    },

    resetPasswordRequest() {
      this.loadingForgotPassword = true;
      appApi
        .postResetPassword({
          email: this.forgotPassword.email,
          phone:
            this.forgotPassword.phone &&
            this.forgotPassword.phone.replace(/\D/g, ''),
        })
        .then(this.handleSuccess)
        .catch(this.handleError)
        .finally(() => {
          this.loadingForgotPassword = false;
        });
    },

    showForgotPasswordDialog() {
      this.forgotPasswordDialog = true;
      this.forgotPassword = {
        email: '',
        phone: '',
        userEmail: '',
      };
      this.inForgotForm = true;
      this.alert = {};
      this.showForgotUserEmail = false;
      this.validation = {
        email: { error: false, message: '' },
        phone: { error: false, message: '' },
      };
    },
    login(token) {
      this.loading = true;
      this.loginError = false;
      let data = { email: this.model.email, password: this.model.password };
      if (token) {
        data['authToken'] = token;
      }
      try {
        let res = this.$auth
          .login({
            data: data,
            rememberMe: true,
            fetchUser: true,
          })
          .then((resp) => {})
          .catch((error) => {
            console.log(error.message);
            if (error.response && error.response.status == 401) {
              this.loginMessageError = 'Usuário ou senha inválido.';
            } else {
              if (error.message) {
                this.loginMessageError = error.message;
              } else {
                this.loginMessageError = JSON.stringify(error);
              }
            }
            this.loading = false;
            this.loginError = true;
          });
      } catch (error) {
        this.loading = false;
        this.loginError = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
#loginPage::v-deep {
  .container {
    padding: 0;

    #info-box {
      background-color: $primary-color;
      min-height: 100vh;
      color: white;

      .text-container {
        max-width: 376px;
        width: 100%;
        text-align: center;

        h1 {
          margin-bottom: 24px;
          font-weight: 600;
        }
      }

      .redes-sociais {
        min-width: 210px;
      }
    }

    #login-box {
      min-height: 100vh;

      .form-container {
        width: 100%;

        form {
          width: 100%;
          max-width: 800px;

          .v-input__prepend-inner {
            padding-right: 18px;

            .v-input__icon {
              padding-left: 5px;

              i {
                color: $secondary-color;
                font-size: 18px;
              }
            }
          }

          .v-label {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
